import React, { useState } from 'react';
import { Form, Input, Button, Select, message } from 'antd';
import { SendOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { Option } = Select;

const ContactForm = () => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);

  const validatePhone = (_, value) => {
    const phoneRegex = /^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/;
    if (!value) {
      return Promise.reject('Please input your phone number!');
    }
    if (!phoneRegex.test(value)) {
      return Promise.reject('Please enter a valid Indian phone number!');
    }
    return Promise.resolve();
  };

  const encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  const onFinish = async (values) => {
    setSubmitting(true);
    try {
      await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": "enterprise_contact",
          ...values
        })
      });
      
      message.success('Thank you for your inquiry! We will get back to you soon.');
      form.resetFields();
    } catch (error) {
      message.error('Something went wrong. Please try again.');
    }
    setSubmitting(false);
  };

  return (
    <Form
      form={form}
      name="enterprise_contact"
      layout="vertical"
      requiredMark={false}
      onFinish={onFinish}
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      method="POST"
    >
      <input type="hidden" name="form-name" value="enterprise_contact" />
      <p hidden>
        <label>
          Don't fill this out if you're human: <input name="bot-field" />
        </label>
      </p>

      <Form.Item
        name="name"
        label="Name"
        rules={[
          { required: true, message: 'Please input your name!' },
          { min: 2, message: 'Name must be at least 2 characters!' }
        ]}
      >
        <Input size="large" placeholder="Your full name" />
      </Form.Item>

      <Form.Item
        name="companyName"
        label="Company Name"
        rules={[
          { required: true, message: 'Please input your company name!' },
          { min: 2, message: 'Company name must be at least 2 characters!' }
        ]}
      >
        <Input size="large" placeholder="Your company name" />
      </Form.Item>

      <Form.Item
        name="email"
        label="Email"
        rules={[
          { required: true, message: 'Please input your email!' },
          { type: 'email', message: 'Please enter a valid email!' }
        ]}
      >
        <Input size="large" placeholder="Your work email" />
      </Form.Item>

      <Form.Item
        name="phone"
        label="Phone Number"
        rules={[{ validator: validatePhone }]}
      >
        <Input
          size="large"
          placeholder="Your phone number"
          addonBefore={
            <Select defaultValue="+91" style={{ width: 70 }}>
              <Option value="+91">+91</Option>
            </Select>
          }
        />
      </Form.Item>

      <Form.Item
        name="companySize"
        label="Company Size"
        rules={[{ required: true, message: 'Please select your company size!' }]}
      >
        <Select size="large" placeholder="Select company size">
          <Option value="1-10">1-10 employees</Option>
          <Option value="11-50">11-50 employees</Option>
          <Option value="51-200">51-200 employees</Option>
          <Option value="201-500">201-500 employees</Option>
          <Option value="501+">501+ employees</Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="message"
        label="Message"
        rules={[{ required: true, message: 'Please input your message!' }]}
      >
        <TextArea
          rows={4}
          placeholder="Tell us about your requirements"
        />
      </Form.Item>

      <Form.Item>
        <Button 
          type="primary" 
          htmlType="submit" 
          size="large" 
          block
          icon={<SendOutlined />}
          loading={submitting}
        >
          Submit Inquiry
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ContactForm;