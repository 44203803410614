// src/components/Layout/Header.js
import React, { useContext } from 'react';
import { Layout, Menu, Dropdown, Avatar, Space, Typography } from 'antd';
import { 
  LogoutOutlined, 
  SettingOutlined,
  DashboardOutlined,
  TeamOutlined,
  SwapOutlined
} from '@ant-design/icons';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import '../../styles/Header.css'

const { Header } = Layout;
const { Title } = Typography;

const LumioLogo = () => (
  <svg 
    width="40" 
    height="40" 
    viewBox="0 0 1080 1080" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    style={{
      minWidth: '40px',
      minHeight: '40px'
    }}
  >
    <rect width="1080" height="1080" rx="200" fill="#1A1A1A"/>
    <ellipse cx="540.501" cy="539.937" rx="58.5667" ry="58.5667" fill="#DDDDDD"/>
    <ellipse cx="700.883" cy="451.636" rx="58.5667" ry="58.5667" fill="#DDDDDD"/>
    <ellipse cx="380.117" cy="451.636" rx="58.5667" ry="58.5667" fill="#DDDDDD"/>
    <ellipse cx="379.217" cy="266.925" rx="36.0409" ry="36.0409" fill="#DDDDDD"/>
    <ellipse cx="226.041" cy="539.036" rx="36.0409" ry="36.0409" fill="#DDDDDD"/>
    <circle cx="226.942" cy="352.523" r="22.5253" fill="#DDDDDD"/>
    <circle cx="226.942" cy="725.549" r="22.5253" fill="#DDDDDD"/>
    <circle cx="854.057" cy="725.549" r="22.5253" fill="#DDDDDD"/>
    <circle cx="540.5" cy="900.348" r="22.5253" fill="#DDDDDD"/>
    <circle cx="540.5" cy="179.525" r="22.5253" fill="#DDDDDD"/>
    <circle cx="854.057" cy="352.523" r="22.5253" fill="#DDDDDD"/>
    <ellipse cx="854.959" cy="539.036" rx="36.0409" ry="36.0409" fill="#DDDDDD"/>
    <ellipse cx="379.217" cy="816.553" rx="36.0409" ry="36.0409" fill="#DDDDDD"/>
    <ellipse cx="701.784" cy="816.553" rx="36.0409" ry="36.0409" fill="#DDDDDD"/>
    <ellipse cx="701.784" cy="266.925" rx="36.0409" ry="36.0409" fill="#DDDDDD"/>
    <ellipse cx="700.883" cy="631.842" rx="58.5667" ry="58.5667" fill="#DDDDDD"/>
    <ellipse cx="380.117" cy="631.842" rx="58.5667" ry="58.5667" fill="#DDDDDD"/>
    <ellipse cx="540.501" cy="352.523" rx="58.5667" ry="58.5667" fill="#DDDDDD"/>
    <ellipse cx="540.501" cy="745.372" rx="58.5667" ry="58.5667" fill="#DDDDDD"/>
  </svg>
);

const AppHeader = () => {
  const { currentUser, companyData, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };

  const profileMenu = (
    <Menu>
      <Menu.Item key="1" icon={<SettingOutlined />}>
        <Link to="/company-profile">Company Profile</Link>
      </Menu.Item>
      <Menu.Item key="2" onClick={handleLogout} icon={<LogoutOutlined />}>
        Logout
      </Menu.Item>
    </Menu>
  );

  const menuItems = [
    {
      key: "/",
      icon: <DashboardOutlined />,
      label: "Dashboard",
    },
    {
      key: "/shareholders",
      icon: <TeamOutlined />,
      label: "Shareholders",
    },
    {
      key: "/transactions",
      icon: <SwapOutlined />,
      label: "Transactions",
    },
  ];

  return (
    <div className="platform-header">
      <Header className="main-header">
        <div className="header-logo-section">
          <div className="logo-wrapper">
            <LumioLogo />
          </div>
          <Title level={4}>lumioCapital <span className="version-tag">v0.2</span></Title>
        </div>

        {currentUser && (
          <div className="user-section">
            <Dropdown 
              overlay={profileMenu} 
              placement="bottomRight" 
              trigger={['click']}
            >
              <div className="user-profile">
                <span className="company-name">
                  {companyData?.companyName}
                </span>
                <Avatar className="user-avatar">
                  {companyData?.companyName?.charAt(0)?.toUpperCase()}
                </Avatar>
              </div>
            </Dropdown>
          </div>
        )}
      </Header>

      {currentUser && (
        <Menu
          mode="horizontal"
          selectedKeys={[location.pathname]}
          className="platform-menu"
          items={menuItems.map(item => ({
            key: item.key,
            icon: item.icon,
            label: <Link to={item.key}>{item.label}</Link>,
          }))}
        />
      )}
    </div>
  );
};

export default AppHeader;