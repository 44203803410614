// src/components/Layout/LandingNav.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Drawer, Menu, Spin, Typography } from 'antd';
import { LoadingOutlined, MenuOutlined } from '@ant-design/icons';

const { Title } = Typography;

const LumioLogo = () => (
  <svg width="40" height="40" viewBox="0 0 1080 1080" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="1080" height="1080" rx="200" fill="#1A1A1A"/>
    <ellipse cx="540.501" cy="539.937" rx="58.5667" ry="58.5667" fill="#DDDDDD"/>
    <ellipse cx="700.883" cy="451.636" rx="58.5667" ry="58.5667" fill="#DDDDDD"/>
    <ellipse cx="380.117" cy="451.636" rx="58.5667" ry="58.5667" fill="#DDDDDD"/>
    <ellipse cx="379.217" cy="266.925" rx="36.0409" ry="36.0409" fill="#DDDDDD"/>
    <ellipse cx="226.041" cy="539.036" rx="36.0409" ry="36.0409" fill="#DDDDDD"/>
    <circle cx="226.942" cy="352.523" r="22.5253" fill="#DDDDDD"/>
    <circle cx="226.942" cy="725.549" r="22.5253" fill="#DDDDDD"/>
    <circle cx="854.057" cy="725.549" r="22.5253" fill="#DDDDDD"/>
    <circle cx="540.5" cy="900.348" r="22.5253" fill="#DDDDDD"/>
    <circle cx="540.5" cy="179.525" r="22.5253" fill="#DDDDDD"/>
    <circle cx="854.057" cy="352.523" r="22.5253" fill="#DDDDDD"/>
    <ellipse cx="854.959" cy="539.036" rx="36.0409" ry="36.0409" fill="#DDDDDD"/>
    <ellipse cx="379.217" cy="816.553" rx="36.0409" ry="36.0409" fill="#DDDDDD"/>
    <ellipse cx="701.784" cy="816.553" rx="36.0409" ry="36.0409" fill="#DDDDDD"/>
    <ellipse cx="701.784" cy="266.925" rx="36.0409" ry="36.0409" fill="#DDDDDD"/>
    <ellipse cx="700.883" cy="631.842" rx="58.5667" ry="58.5667" fill="#DDDDDD"/>
    <ellipse cx="380.117" cy="631.842" rx="58.5667" ry="58.5667" fill="#DDDDDD"/>
    <ellipse cx="540.501" cy="352.523" rx="58.5667" ry="58.5667" fill="#DDDDDD"/>
    <ellipse cx="540.501" cy="745.372" rx="58.5667" ry="58.5667" fill="#DDDDDD"/>
  </svg>
);

const LandingNav = ({ title }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [currentSection, setCurrentSection] = useState('home');
  const [menuOpen, setMenuOpen] = useState(false);

  const handleNavigation = (path) => {
    setLoading(true);
    setTimeout(() => {
      navigate(path);
      setLoading(false);
    }, 500);
  };

  const scrollToSection = (sectionId) => {
    setCurrentSection(sectionId);
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    setMenuOpen(false);
  };

  const menuItems = [
    { key: 'home', label: 'Home' },
    { key: 'features', label: 'Features' },
    { key: 'pricing', label: 'Pricing' },
    { key: 'contact', label: 'Contact' }
  ];

  return (
    <nav className="landing-nav">
      <div className="nav-logo">
        <LumioLogo />
        <Title level={3} className="nav-title">{title}</Title>
      </div>

      <div className="nav-menu-desktop">
        <Menu
          mode="horizontal"
          selectedKeys={[currentSection]}
          className="nav-menu"
        >
          {menuItems.map(item => (
            <Menu.Item key={item.key} onClick={() => scrollToSection(item.key)}>
              {item.label}
            </Menu.Item>
          ))}
        </Menu>
      </div>

      <div className="nav-buttons">
        {loading ? (
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        ) : (
          <>
            <Button size="large" type="link" onClick={() => handleNavigation('/login')}>
              Login
            </Button>
            <Button size="large" type="primary" onClick={() => handleNavigation('/register')}>
              Get Started
            </Button>
          </>
        )}
      </div>

      <div className="nav-menu-mobile">
        <Button
          type="link"
          icon={<MenuOutlined />}
          onClick={() => setMenuOpen(true)}
          className="menu-icon"
        />
      </div>

      <Drawer
        title={title}
        placement="right"
        onClose={() => setMenuOpen(false)}
        visible={menuOpen}
        className="mobile-menu-drawer"
      >
        <Menu
          mode="vertical"
          selectedKeys={[currentSection]}
          className="mobile-menu"
        >
          {menuItems.map(item => (
            <Menu.Item key={item.key} onClick={() => scrollToSection(item.key)}>
              {item.label}
            </Menu.Item>
          ))}
          <Menu.Item key="login" onClick={() => handleNavigation('/login')}>
            Login
          </Menu.Item>
          <Menu.Item key="register" onClick={() => handleNavigation('/register')}>
            Get Started
          </Menu.Item>
        </Menu>
      </Drawer>
    </nav>
  );
};

export default LandingNav;