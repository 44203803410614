import React, { useContext, useState, useEffect } from 'react';
import { 
  Card, 
  Form, 
  Input, 
  InputNumber, 
  Button, 
  Modal, 
  Tabs,
  Table,
  Select,
  Space,
  Typography,
  Popconfirm,
  Avatar,
  Tag,
  Tooltip,
  Row,
  Col,
  DatePicker,
  Divider
} from 'antd';
import { 
  UserOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  BuildOutlined,
  TeamOutlined,
  BankOutlined,
  MailOutlined,
  PhoneOutlined,
  GlobalOutlined,
  BookOutlined,
  SaveOutlined,
  AuditOutlined,
  ClockCircleOutlined,
  DollarOutlined,
  CalendarOutlined
} from '@ant-design/icons';

import { AuthContext } from '../contexts/AuthContext';
import { db } from '../firebase';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { validateCIN } from '../utils/cinValidator';
import { validateDIN, DIRECTOR_TYPES, KMP_POSITIONS } from '../utils/dinValidator';
import { validatePhone, validateEmail } from '../utils/validation';
import moment from 'moment';
import '../styles/pages.css';

const { Title, Text } = Typography;
const { TabPane } = Tabs;

const CompanyProfile = () => {
  const { currentUser, companyData, setCompanyData } = useContext(AuthContext);
  const [form] = Form.useForm();
  const [kmpForm] = Form.useForm();
  const [directorForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [editingKMP, setEditingKMP] = useState(null);
  const [editingDirector, setEditingDirector] = useState(null);
  const [kmpModalVisible, setKMPModalVisible] = useState(false);
  const [directorModalVisible, setDirectorModalVisible] = useState(false);

  useEffect(() => {
    const fetchCompanyData = async () => {
      if (currentUser) {
        const companyRef = doc(db, 'companies', currentUser.uid);
        const companySnap = await getDoc(companyRef);
        if (companySnap.exists()) {
          const data = companySnap.data();
          form.setFieldsValue(data);
          setCompanyData(data);
        } else {
          form.resetFields();
          setCompanyData(null);
        }
      }
    };

    fetchCompanyData();
  }, [currentUser, form, setCompanyData]);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const companyRef = doc(db, 'companies', currentUser.uid);
      await setDoc(companyRef, {
        ...values,
        updatedAt: new Date().toISOString(),
        uid: currentUser.uid
      }, { merge: true });

      setCompanyData(values);
      Modal.success({
        title: 'Success',
        content: 'Company details updated successfully.',
      });
    } catch (error) {
      Modal.error({
        title: 'Error',
        content: 'An error occurred while updating company details.',
      });
    }
    setLoading(false);
  };

  const handleKMPSubmit = async (values) => {
    try {
      const companyRef = doc(db, 'companies', currentUser.uid);
      const kmps = companyData?.kmps || [];
      
      if (editingKMP) {
        const updatedKMPs = kmps.map(kmp => 
          kmp.id === editingKMP.id ? { ...values, id: kmp.id } : kmp
        );
        await updateDoc(companyRef, { kmps: updatedKMPs });
      } else {
        const newKMP = { ...values, id: Date.now().toString() };
        await updateDoc(companyRef, { kmps: [...kmps, newKMP] });
      }

      setKMPModalVisible(false);
      kmpForm.resetFields();
      setEditingKMP(null);

      // Refresh company data
      const updatedCompanySnap = await getDoc(companyRef);
      setCompanyData(updatedCompanySnap.data());
      
      Modal.success({
        title: 'Success',
        content: editingKMP ? 'KMP updated successfully.' : 'KMP added successfully.',
      });
    } catch (error) {
      Modal.error({
        title: 'Error',
        content: 'Failed to update KMP details.',
      });
    }
  };

  const handleDirectorSubmit = async (values) => {
    try {
      const companyRef = doc(db, 'companies', currentUser.uid);
      const directors = companyData?.directors || [];
      
      if (editingDirector) {
        const updatedDirectors = directors.map(director => 
          director.id === editingDirector.id ? { 
            ...values, 
            id: director.id,
            appointmentDate: values.appointmentDate.toDate() 
          } : director
        );
        await updateDoc(companyRef, { directors: updatedDirectors });
      } else {
        const newDirector = { 
          ...values, 
          id: Date.now().toString(),
          appointmentDate: values.appointmentDate.toDate()
        };
        await updateDoc(companyRef, { directors: [...directors, newDirector] });
      }

      setDirectorModalVisible(false);
      directorForm.resetFields();
      setEditingDirector(null);

      // Refresh company data
      const updatedCompanySnap = await getDoc(companyRef);
      setCompanyData(updatedCompanySnap.data());
      
      Modal.success({
        title: 'Success',
        content: editingDirector ? 'Director updated successfully.' : 'Director added successfully.',
      });
    } catch (error) {
      Modal.error({
        title: 'Error',
        content: 'Failed to update director details.',
      });
    }
  };

  const handleDeleteKMP = async (kmpId) => {
    try {
      const companyRef = doc(db, 'companies', currentUser.uid);
      const updatedKMPs = companyData.kmps.filter(kmp => kmp.id !== kmpId);
      await updateDoc(companyRef, { kmps: updatedKMPs });

      // Refresh company data
      const updatedCompanySnap = await getDoc(companyRef);
      setCompanyData(updatedCompanySnap.data());
      
      Modal.success({
        title: 'Success',
        content: 'KMP deleted successfully.',
      });
    } catch (error) {
      Modal.error({
        title: 'Error',
        content: 'Failed to delete KMP.',
      });
    }
  };

  const handleDeleteDirector = async (directorId) => {
    try {
      const companyRef = doc(db, 'companies', currentUser.uid);
      const updatedDirectors = companyData.directors.filter(director => director.id !== directorId);
      await updateDoc(companyRef, { directors: updatedDirectors });

      // Refresh company data
      const updatedCompanySnap = await getDoc(companyRef);
      setCompanyData(updatedCompanySnap.data());
      
      Modal.success({
        title: 'Success',
        content: 'Director deleted successfully.',
      });
    } catch (error) {
      Modal.error({
        title: 'Error',
        content: 'Failed to delete director.',
      });
    }
  };

  const CompanyOverviewCard = () => (
    <Card className="company-info-card">
      <div className="company-info-header">
        <Avatar 
          size={80}
          icon={<BankOutlined />}
          className="company-avatar"
        />
        <div className="company-info-title">
          <Title level={3}>{companyData?.companyName || 'Company Name'}</Title>
          <div className="company-meta-info">
            <span className="meta-item">
              <BookOutlined /> {companyData?.cin || 'CIN: Not Available'}
            </span>
            <span className="meta-item">
              <MailOutlined /> {companyData?.email || 'Email not available'}
            </span>
            <span className="meta-item">
              <PhoneOutlined /> {companyData?.phone || 'Phone not available'}
            </span>
            {companyData?.website && (
              <span className="meta-item">
                <GlobalOutlined /> {companyData.website}
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="company-stats">
        <div className="stat-card">
          <DollarOutlined className="stat-icon" />
          <div className="stat-label">Authorized Capital</div>
          <div className="stat-value">₹ {companyData?.authorizedCapital?.toLocaleString() || '0'}</div>
        </div>
        <div className="stat-card">
          <DollarOutlined className="stat-icon" />
          <div className="stat-label">Paid-up Capital</div>
          <div className="stat-value">₹ {companyData?.paidUpCapital?.toLocaleString() || '0'}</div>
        </div>
        <div className="stat-card">
          <DollarOutlined className="stat-icon" />
          <div className="stat-label">Share Value</div>
          <div className="stat-value">₹ {companyData?.nominalShareValue?.toLocaleString() || '0'}</div>
        </div>
      </div>
    </Card>
  );

  const kmpColumns = [
    {
      title: 'Name & Position',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div className="person-info">
          <Avatar icon={<UserOutlined />} className="table-avatar" />
          <div>
            <Text className="person-name">{text}</Text>
            <div className="person-position">
              <Tag color="blue">{record.position}</Tag>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'Contact Information',
      key: 'contact',
      render: (_, record) => (
        <Space direction="vertical" size={2}>
          <Text>
            <MailOutlined className="contact-icon" /> {record.email}
          </Text>
          <Text>
            <PhoneOutlined className="contact-icon" /> {record.phone}
          </Text>
        </Space>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 120,
      render: (_, record) => (
        <Space size="middle" className="action-buttons">
          <Button 
            type="text" 
            icon={<EditOutlined />}
            className="edit-button"
            onClick={() => {
              setEditingKMP(record);
              kmpForm.setFieldsValue(record);
              setKMPModalVisible(true);
            }}
          />
          <Popconfirm
            title="Remove KMP"
            description="Are you sure you want to remove this KMP?"
            onConfirm={() => handleDeleteKMP(record.id)}
            okText="Yes"
            cancelText="No"
            okButtonProps={{ danger: true }}
          >
            <Button 
              type="text" 
              danger 
              icon={<DeleteOutlined />}
              className="delete-button"
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const directorColumns = [
    {
      title: 'Name & Details',
      key: 'name',
      render: (_, record) => (
        <div className="person-info">
          <Avatar icon={<UserOutlined />} className="table-avatar" />
          <div>
            <Text className="person-name">{record.name}</Text>
            <div>
              <Tag color="purple">DIN: {record.din}</Tag>
              <Tag color="green">{record.type}</Tag>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'Contact & Appointment',
      key: 'details',
      render: (_, record) => (
        <Space direction="vertical" size={4}>
          <Text>
            <MailOutlined className="contact-icon" /> {record.email}
          </Text>
          <Text>
            <PhoneOutlined className="contact-icon" /> {record.phone}
          </Text>
          <Text>
            <CalendarOutlined className="contact-icon" /> Appointed: {
              moment(record.appointmentDate).format('DD MMM YYYY')
            }
          </Text>
        </Space>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 120,
      render: (_, record) => (
        <Space size="middle" className="action-buttons">
          <Button 
            type="text" 
            icon={<EditOutlined />}
            className="edit-button"
            onClick={() => {
              setEditingDirector(record);
              directorForm.setFieldsValue({
                ...record,
                appointmentDate: moment(record.appointmentDate)
              });
              setDirectorModalVisible(true);
            }}
          />
          <Popconfirm
            title="Remove Director"
            description="Are you sure you want to remove this director?"
            onConfirm={() => handleDeleteDirector(record.id)}
            okText="Yes"
            cancelText="No"
            okButtonProps={{ danger: true }}
          >
            <Button 
              type="text" 
              danger 
              icon={<DeleteOutlined />}
              className="delete-button"
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div className="page-container">
      <div className="profile-header">
        <Title level={2} className="page-title">Company Profile</Title>
        <Text className="page-subtitle">Manage your company information and leadership</Text>
      </div>

      <CompanyOverviewCard />

      <Tabs 
        activeKey={activeTab} 
        onChange={setActiveTab}
        className="profile-tabs"
        type="card"
      >
        <TabPane 
          tab={
            <span className="tab-label">
              <BuildOutlined />
              Company Details
            </span>
          } 
          key="1"
        >
          <div className="form-section">
            <Form 
              form={form} 
              onFinish={onFinish} 
              layout="vertical"
              requiredMark={false}
              className="custom-form"
            >
              <Row gutter={24}>
                <Col xs={24} md={12}>
                  <Form.Item
                    label="Company Name"
                    name="companyName"
                    rules={[{ required: true, message: 'Please input company name!' }]}
                  >
                    <Input 
                      prefix={<BankOutlined className="form-icon" />} 
                      placeholder="Enter company name" 
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    label="CIN"
                    name="cin"
                    rules={[{ validator: validateCIN }]}
                    tooltip="Example: U74999TN2023PTC123456"
                  >
                    <Input 
                      prefix={<BookOutlined className="form-icon" />} 
                      placeholder="Enter CIN"
                      maxLength={21}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col xs={24} md={12}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ validator: validateEmail }]}
                  >
                    <Input 
                      prefix={<MailOutlined className="form-icon" />} 
                      placeholder="Enter company email" 
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    label="Phone"
                    name="phone"
                    rules={[{ validator: validatePhone }]}
                  >
                    <Input 
                      prefix={<PhoneOutlined className="form-icon" />} 
                      placeholder="Enter company phone" 
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                label="Website"
                name="website"
              >
                <Input 
                  prefix={<GlobalOutlined className="form-icon" />} 
                  placeholder="Enter company website" 
                />
              </Form.Item>

              <div className="capital-input-group">
              <Title level={5} className="section-title">Capital Information</Title>
              <Row gutter={24}>
                <Col xs={24} lg={8}>
                  <Form.Item
                    label={
                      <span className="capital-label">
                        Authorized Capital (INR)
                      </span>
                    }
                    name="authorizedCapital"
                    rules={[{ required: true, message: 'Please input authorized capital!' }]}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      formatter={(value) => `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value.replace(/₹\s?|(,*)/g, '')}
                      min={0}
                      placeholder="Enter authorized capital"
                      size="large"
                      controls={false}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={8}>
                  <Form.Item
                    label={
                      <span className="capital-label">
                        Paid-up Capital (INR)
                      </span>
                    }
                    name="paidUpCapital"
                    rules={[
                      { required: true, message: 'Please input paid-up capital!' },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('authorizedCapital') >= value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('Paid-up capital cannot exceed authorized capital!'));
                        },
                      }),
                    ]}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      formatter={(value) => `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value.replace(/₹\s?|(,*)/g, '')}
                      min={0}
                      placeholder="Enter paid-up capital"
                      size="large"
                      controls={false}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={8}>
                  <Form.Item
                    label={
                      <span className="capital-label">
                        Nominal Share Value (INR)
                      </span>
                    }
                    name="nominalShareValue"
                    rules={[
                      { required: true, message: 'Please input nominal share value!' },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('paidUpCapital') % value === 0) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('Paid-up capital should be divisible by nominal share value!'));
                        },
                      }),
                    ]}
                    tooltip="This value represents the face value of each share"
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      formatter={(value) => `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value.replace(/₹\s?|(,*)/g, '')}
                      min={1}
                      placeholder="Enter nominal share value"
                      size="large"
                      controls={false}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>

              <Form.Item className="form-actions">
                <Button 
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  icon={<SaveOutlined />}
                  size="large"
                >
                  Update Company Details
                </Button>
              </Form.Item>
            </Form>
          </div>
        </TabPane>

        <TabPane 
          tab={
            <span className="tab-label">
              <UserOutlined />
              Key Personnel
            </span>
          } 
          key="2"
        >
          <Card className="table-card">
            <div className="table-header">
              <Space align="center">
                <Title level={4} style={{ margin: 0 }}>Key Managerial Personnel</Title>
                <Tag color="blue">{companyData?.kmps?.length || 0} KMPs</Tag>
              </Space>
              <Button 
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setEditingKMP(null);
                  kmpForm.resetFields();
                  setKMPModalVisible(true);
                }}
              >
                Add KMP
              </Button>
            </div>

            <Table 
              columns={kmpColumns} 
              dataSource={companyData?.kmps || []}
              rowKey="id"
              className="custom-table"
              pagination={false}
            />
          </Card>

          <Modal
            title={
              <div className="modal-title">
                <UserOutlined className="modal-title-icon" />
                <span>{editingKMP ? "Edit KMP Details" : "Add New KMP"}</span>
              </div>
            }
            open={kmpModalVisible}
            onCancel={() => {
              setKMPModalVisible(false);
              setEditingKMP(null);
              kmpForm.resetFields();
            }}
            footer={null}
            className="custom-modal"
          >
            <Form
              form={kmpForm}
              layout="vertical"
              onFinish={handleKMPSubmit}
            >
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Please input name!' }]}
              >
                <Input prefix={<UserOutlined />} placeholder="Enter full name" />
              </Form.Item>

              <Form.Item
                label="Position"
                name="position"
                rules={[{ required: true, message: 'Please select position!' }]}
              >
                <Select placeholder="Select position">
                  {KMP_POSITIONS.map(position => (
                    <Select.Option key={position} value={position}>
                      {position}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Email"
                name="email"
                rules={[{ validator: validateEmail }]}
              >
                <Input prefix={<MailOutlined />} placeholder="Enter email" />
              </Form.Item>

              <Form.Item
                label="Phone"
                name="phone"
                rules={[{ validator: validatePhone }]}
              >
                <Input prefix={<PhoneOutlined />} placeholder="Enter phone number" />
              </Form.Item>

              <Form.Item className="modal-footer">
                <Space>
                  <Button 
                    onClick={() => {
                      setKMPModalVisible(false);
                      setEditingKMP(null);
                      kmpForm.resetFields();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button type="primary" htmlType="submit">
                    {editingKMP ? 'Update' : 'Add'} KMP
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Modal>
        </TabPane>

        <TabPane 
          tab={
            <span className="tab-label">
              <TeamOutlined />
              Board of Directors
            </span>
          } 
          key="3"
        >
          <Card className="table-card">
            <div className="table-header">
              <Space align="center">
                <Title level={4} style={{ margin: 0 }}>Board of Directors</Title>
                <Tag color="blue">{companyData?.directors?.length || 0} Directors</Tag>
              </Space>
              <Button 
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setEditingDirector(null);
                  directorForm.resetFields();
                  setDirectorModalVisible(true);
                }}
              >
                Add Director
              </Button>
            </div>

            <Table 
              columns={directorColumns} 
              dataSource={companyData?.directors || []}
              rowKey="id"
              className="custom-table"
              pagination={false}
            />
          </Card>

          <Modal
            title={
              <div className="modal-title">
                <TeamOutlined className="modal-title-icon" />
                <span>{editingDirector ? "Edit Director Details" : "Add New Director"}</span>
              </div>
            }
            open={directorModalVisible}
            onCancel={() => {
              setDirectorModalVisible(false);
              setEditingDirector(null);
              directorForm.resetFields();
            }}
            footer={null}
            className="custom-modal"
          >
            <Form
              form={directorForm}
              layout="vertical"
              onFinish={handleDirectorSubmit}
            >
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Please input name!' }]}
              >
                <Input prefix={<UserOutlined />} placeholder="Enter full name" />
              </Form.Item>

              <Form.Item
                label="DIN"
                name="din"
                rules={[{ validator: validateDIN }]}
                tooltip="Enter 8-digit Director Identification Number"
              >
                <Input prefix={<AuditOutlined />} placeholder="Enter DIN" maxLength={8} />
              </Form.Item>

              <Form.Item
                label="Type"
                name="type"
                rules={[{ required: true, message: 'Please select director type!' }]}
              >
                <Select placeholder="Select director type">
                  {DIRECTOR_TYPES.map(type => (
                    <Select.Option key={type} value={type}>
                      {type}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Appointment Date"
                name="appointmentDate"
                rules={[{ required: true, message: 'Please select appointment date!' }]}
              >
                <DatePicker 
                  style={{ width: '100%' }} 
                  format="DD/MM/YYYY"
                />
              </Form.Item>

              <Form.Item
                label="Email"
                name="email"
                rules={[{ validator: validateEmail }]}
              >
                <Input prefix={<MailOutlined />} placeholder="Enter email" />
              </Form.Item>

              <Form.Item
                label="Phone"
                name="phone"
                rules={[{ validator: validatePhone }]}
              >
                <Input prefix={<PhoneOutlined />} placeholder="Enter phone number" />
              </Form.Item>

              <Form.Item className="modal-footer">
                <Space>
                  <Button 
                    onClick={() => {
                      setDirectorModalVisible(false);
                      setEditingDirector(null);
                      directorForm.resetFields();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button type="primary" htmlType="submit">
                    {editingDirector ? 'Update' : 'Add'} Director
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Modal>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default CompanyProfile;