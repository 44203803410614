import React, { useState, useContext } from 'react';
import {
  Button,
  Form,
  Input,
  Typography,
  Divider,
  InputNumber,
  Layout,
  message
} from 'antd';
import { useNavigate, Link } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import { validateCIN } from '../../utils/cinValidator';
import AppFooter from '../Layout/Footer';
import '../../styles/Auth.css';

const { Title, Text } = Typography;
const { Content } = Layout;

const LumioLogo = () => (
  <svg width="40" height="40" viewBox="0 0 1080 1080" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="1080" height="1080" rx="200" fill="#1A1A1A"/>
    <ellipse cx="540.501" cy="539.937" rx="58.5667" ry="58.5667" fill="#DDDDDD"/>
    <ellipse cx="700.883" cy="451.636" rx="58.5667" ry="58.5667" fill="#DDDDDD"/>
    <ellipse cx="380.117" cy="451.636" rx="58.5667" ry="58.5667" fill="#DDDDDD"/>
    <ellipse cx="379.217" cy="266.925" rx="36.0409" ry="36.0409" fill="#DDDDDD"/>
    <ellipse cx="226.041" cy="539.036" rx="36.0409" ry="36.0409" fill="#DDDDDD"/>
    <circle cx="226.942" cy="352.523" r="22.5253" fill="#DDDDDD"/>
    <circle cx="226.942" cy="725.549" r="22.5253" fill="#DDDDDD"/>
    <circle cx="854.057" cy="725.549" r="22.5253" fill="#DDDDDD"/>
    <circle cx="540.5" cy="900.348" r="22.5253" fill="#DDDDDD"/>
    <circle cx="540.5" cy="179.525" r="22.5253" fill="#DDDDDD"/>
    <circle cx="854.057" cy="352.523" r="22.5253" fill="#DDDDDD"/>
    <ellipse cx="854.959" cy="539.036" rx="36.0409" ry="36.0409" fill="#DDDDDD"/>
    <ellipse cx="379.217" cy="816.553" rx="36.0409" ry="36.0409" fill="#DDDDDD"/>
    <ellipse cx="701.784" cy="816.553" rx="36.0409" ry="36.0409" fill="#DDDDDD"/>
    <ellipse cx="701.784" cy="266.925" rx="36.0409" ry="36.0409" fill="#DDDDDD"/>
    <ellipse cx="700.883" cy="631.842" rx="58.5667" ry="58.5667" fill="#DDDDDD"/>
    <ellipse cx="380.117" cy="631.842" rx="58.5667" ry="58.5667" fill="#DDDDDD"/>
    <ellipse cx="540.501" cy="352.523" rx="58.5667" ry="58.5667" fill="#DDDDDD"/>
    <ellipse cx="540.501" cy="745.372" rx="58.5667" ry="58.5667" fill="#DDDDDD"/>
  </svg>
);

const Register = () => {
  const { register } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    setLoading(true);
    try {
      await register(values.email, values.password, {
        companyName: values.companyName,
        cin: values.cin,
        authorizedCapital: values.authorizedCapital,
        paidUpCapital: values.paidUpCapital,
        nominalShareValue: values.nominalShareValue,
      });
      message.success('Registration successful!');
      navigate('/');
    } catch (error) {
      message.error(error.message || 'Registration failed. Please try again.');
      console.error('Registration error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <nav className="landing-nav">
        <div className="nav-logo">
          <LumioLogo />
          <Link to="/">
            <Title level={3}>lumioCapital</Title>
          </Link>
        </div>
        <div className="nav-buttons">
          <Button size="large" type="link" onClick={() => navigate('/login')}>Login</Button>
          <Button size="large" type="primary" onClick={() => navigate('/register')}>Get Started</Button>
        </div>
      </nav>

      <Content>
        <div className="auth-container">
          <div className="auth-background">
            <div className="auth-circle auth-circle-1" />
            <div className="auth-circle auth-circle-2" />
          </div>

          <div className="auth-card">
            <div className="auth-header">
              <Title level={2}>Create Account</Title>
              <Text type="secondary">Set up your company's shareholding platform</Text>
            </div>

            <Form
              form={form}
              name="register"
              onFinish={onFinish}
              layout="vertical"
              className="auth-form"
              autoComplete="off"
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: 'Please enter your email' },
                  { type: 'email', message: 'Please enter a valid email' }
                ]}
              >
                <Input size="large" placeholder="Enter your email" />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: 'Please enter your password' },
                  { min: 8, message: 'Password must be at least 8 characters' }
                ]}
                hasFeedback
              >
                <Input.Password size="large" placeholder="Create a password" />
              </Form.Item>

              <Form.Item
                label="Confirm Password"
                name="confirm"
                dependencies={['password']}
                hasFeedback
                rules={[
                  { required: true, message: 'Please confirm your password' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('The two passwords do not match'));
                    },
                  }),
                ]}
              >
                <Input.Password size="large" placeholder="Confirm your password" />
              </Form.Item>

              <Divider>Company Details</Divider>

              <Form.Item
                label="Company Name"
                name="companyName"
                rules={[{ required: true, message: 'Please enter your company name' }]}
              >
                <Input size="large" placeholder="Enter company name" />
              </Form.Item>

              <Form.Item
                label="Corporate Identification Number (CIN)"
                name="cin"
                rules={[{ validator: validateCIN }]}
              >
                <Input size="large" placeholder="Enter CIN" maxLength={21} />
              </Form.Item>

              <Form.Item
                label="Authorized Capital (INR)"
                name="authorizedCapital"
                rules={[{ required: true, message: 'Please enter authorized capital' }]}
              >
                <InputNumber
                  size="large"
                  style={{ width: '100%' }}
                  formatter={value => `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/₹\s?|(,*)/g, '')}
                  min={0}
                />
              </Form.Item>

              <Form.Item
                label="Paid-up Capital (INR)"
                name="paidUpCapital"
                rules={[{ required: true, message: 'Please enter paid-up capital' }]}
              >
                <InputNumber
                  size="large"
                  style={{ width: '100%' }}
                  formatter={value => `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/₹\s?|(,*)/g, '')}
                  min={0}
                />
              </Form.Item>

              <Form.Item
                label="Nominal Value of Share (INR)"
                name="nominalShareValue"
                rules={[{ required: true, message: 'Please enter nominal share value' }]}
              >
                <InputNumber
                  size="large"
                  style={{ width: '100%' }}
                  formatter={value => `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/₹\s?|(,*)/g, '')}
                  min={1}
                />
              </Form.Item>

              <Form.Item>
                <Button 
                  type="primary"
                  htmlType="submit"
                  block
                  size="large"
                  loading={loading}
                >
                  Create Account
                </Button>
              </Form.Item>

              <div className="auth-footer">
                <Text type="secondary">
                  Already have an account? <Link to="/login">Sign in</Link>
                </Text>
              </div>
            </Form>
          </div>

          <div className="auth-features">
            <Title level={3}>Get Started In Minutes</Title>
            <ul>
              <li>Quick and easy setup process</li>
              <li>Secure data encryption</li>
              <li>Instant access to all features</li>
              <li>Dedicated support team</li>
            </ul>
          </div>
        </div>
      </Content>
      <AppFooter />
    </Layout>
  );
};

export default Register;