// src/components/Shareholders/AddShareholder.js

import React, { useState, useContext } from 'react';
import {
  Form,
  Input,
  Button,
  Card,
  InputNumber,
  Tooltip,
  Space,
  Checkbox,
  Modal,
} from 'antd';
import {
  InfoCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { db } from '../../firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { AuthContext } from '../../contexts/AuthContext';

const AddShareholder = () => {
  const [isJoint, setIsJoint] = useState(false);
  const [isDummy, setIsDummy] = useState(false);
  const [form] = Form.useForm();
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { currentUser } = useContext(AuthContext);

  const onFinish = async () => {
    const values = form.getFieldsValue();

    // Check if not dummy and no certificates are added
    if (!isDummy && (!values.certificates || values.certificates.length === 0)) {
      Modal.error({
        title: 'Add Certificates',
        content: `Please add certificates for ${values.folioNumber} - ${values.names && values.names[0] ? values.names[0] : 'Shareholder'}.`,
      });
      return;
    }

    setConfirmVisible(true);
  };

  const handleConfirmOk = async () => {
    setConfirmLoading(true);
    try {
      const values = form.getFieldsValue();
      let totalShares = 0;
      let certificates = [];

      if (!isDummy) {
        certificates = values.certificates.map((cert) => {
          const totalCertShares =
            cert.distinctiveNumberEnd - cert.distinctiveNumberStart + 1;
          totalShares += totalCertShares;
          return {
            certificateNumber: cert.certificateNumber,
            distinctiveNumberStart: cert.distinctiveNumberStart,
            distinctiveNumberEnd: cert.distinctiveNumberEnd,
            totalShares: totalCertShares,
          };
        });
      }

      // Set initial values to NIL if Dummy Shareholder
      const shareholderData = {
        folioNumber: values.folioNumber,
        names: values.names,
        isJoint: isJoint,
        isDummy: isDummy,
        certificates: isDummy ? [] : certificates,
        totalShares: isDummy ? 0 : totalShares,
        status: isDummy ? 'Dummy' : 'Active',
        createdAt: serverTimestamp(),
        companyId: currentUser.uid, // Add company ID
      };

      await addDoc(collection(db, 'shareholders'), shareholderData);

      form.resetFields();
      setIsJoint(false);
      setIsDummy(false);

      Modal.success({
        title: 'Success',
        content: 'Shareholder added successfully.',
      });
    } catch (error) {
      console.error(error);
      Modal.error({
        title: 'Error',
        content: 'An error occurred while adding the shareholder.',
      });
    }
    setConfirmLoading(false);
    setConfirmVisible(false);
  };

  const handleConfirmCancel = () => {
    setConfirmVisible(false);
  };

  const handleDummyChange = (e) => {
    setIsDummy(e.target.checked);
    if (e.target.checked) {
      form.setFieldsValue({
        certificates: [],
      });
    }
  };

  return (
    <Card title="Add Shareholder" style={{ marginTop: '20px' }}>
      <Form
        form={form}
        name="add_shareholder"
        onFinish={onFinish}
        layout="vertical"
      >
        <Form.Item
          label={
            <span>
              Folio Number&nbsp;
              <Tooltip title="Unique identifier for the shareholder">
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          }
          name="folioNumber"
          rules={[{ required: true, message: 'Please input the folio number!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item name="isDummy" valuePropName="checked">
          <Checkbox onChange={handleDummyChange}>
            Dummy Shareholder&nbsp;
            <Tooltip title="This is created when a transfer is to be done to a new shareholder directly">
              <InfoCircleOutlined />
            </Tooltip>
          </Checkbox>
        </Form.Item>

        <Form.Item name="isJoint" valuePropName="checked">
          <Checkbox onChange={(e) => setIsJoint(e.target.checked)}>
            Joint Shareholders
          </Checkbox>
        </Form.Item>

        {/* If joint not checked, show only one name field */}
        {!isJoint && (
          <Form.Item
            label="Shareholder Name"
            name={['names', 0]}
            rules={[{ required: true, message: 'Please input a name!' }]}
          >
            <Input placeholder="Name" />
          </Form.Item>
        )}

        {/* If joint is checked, allow multiple names */}
        {isJoint && (
          <Form.List name="names" initialValue={['']}>
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <Space
                    key={field.key}
                    style={{ display: 'flex', marginBottom: 8 }}
                    align="start"
                  >
                    <Form.Item
                      {...field}
                      label={`Shareholder Name ${index + 1}`}
                      name={[field.name]}
                      fieldKey={[field.fieldKey]}
                      rules={[
                        { required: true, message: 'Please input a name!' },
                      ]}
                    >
                      <Input placeholder="Name" />
                    </Form.Item>
                    {fields.length > 1 && (
                      <MinusCircleOutlined
                        onClick={() => remove(field.name)}
                        style={{ marginTop: '30px' }}
                      />
                    )}
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add Name
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        )}

        {!isDummy && (
          <Form.List name="certificates">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <Card
                    key={field.key}
                    type="inner"
                    title={`Certificate ${index + 1}`}
                    style={{ marginBottom: '16px' }}
                    extra={
                      fields.length > 1 && (
                        <MinusCircleOutlined onClick={() => remove(field.name)} />
                      )
                    }
                  >
                    <Form.Item
                      label="Certificate Number"
                      name={[field.name, 'certificateNumber']}
                      fieldKey={[field.fieldKey, 'certificateNumber']}
                      rules={[
                        {
                          required: true,
                          message: 'Please input certificate number!',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      label="Distinctive Number Start"
                      name={[field.name, 'distinctiveNumberStart']}
                      fieldKey={[field.fieldKey, 'distinctiveNumberStart']}
                      rules={[
                        {
                          required: true,
                          message: 'Please input distinctive number start!',
                        },
                      ]}
                    >
                      <InputNumber min={1} style={{ width: '100%' }} />
                    </Form.Item>

                    <Form.Item
                      label="Distinctive Number End"
                      name={[field.name, 'distinctiveNumberEnd']}
                      fieldKey={[field.fieldKey, 'distinctiveNumberEnd']}
                      rules={[
                        {
                          required: true,
                          message: 'Please input distinctive number end!',
                        },
                      ]}
                    >
                      <InputNumber min={1} style={{ width: '100%' }} />
                    </Form.Item>
                  </Card>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add Certificate
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        )}

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Add Shareholder
          </Button>
        </Form.Item>
      </Form>

      <Modal
        title="Confirm Shareholder Addition"
        visible={confirmVisible}
        onOk={handleConfirmOk}
        confirmLoading={confirmLoading}
        onCancel={handleConfirmCancel}
        okText="Confirm"
      >
        <p>Are you sure you want to add this shareholder?</p>
      </Modal>
    </Card>
  );
};

export default AddShareholder;