import React, { useState, useEffect, useContext } from 'react';
import {
  Form,
  Input,
  Button,
  Card,
  DatePicker,
  Modal,
  Radio,
  InputNumber,
  Select,
  Typography,
  Alert
} from 'antd';
import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  setDoc,
  serverTimestamp
} from 'firebase/firestore';
import { db } from '../../firebase';
import moment from 'moment';
import { AuthContext } from '../../contexts/AuthContext';
import { InfoCircleOutlined } from '@ant-design/icons';

const { Option } = Select;
const { Text } = Typography;

const COMPANY_INCORPORATION_YEAR = 2020; // Example: Replace with your actual incorporation year or fetch dynamically

const TransferShares = () => {
  const [form] = Form.useForm();
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [transferType, setTransferType] = useState('full');
  const [shareholderData, setShareholderData] = useState(null);
  const [shareholders, setShareholders] = useState([]);
  const [transfereeExists, setTransfereeExists] = useState(false);
  const [newShareholderModalVisible, setNewShareholderModalVisible] = useState(false);
  const [selectedCertificate, setSelectedCertificate] = useState(null);
  const [availableRanges, setAvailableRanges] = useState([]);
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const fetchShareholders = async () => {
      try {
        if (!currentUser) return;

        const shareholdersRef = collection(db, 'shareholders');
        const q = query(shareholdersRef, where("companyId", "==", currentUser.uid));
        const querySnapshot = await getDocs(q);
        const shareholdersList = [];
        querySnapshot.forEach((docSnap) => {
          const data = docSnap.data();
          shareholdersList.push({ id: docSnap.id, ...data });
        });
        setShareholders(shareholdersList);
      } catch (error) {
        console.error('Error fetching shareholders:', error);
        Modal.error({
          title: 'Error',
          content: 'Failed to fetch shareholders list',
        });
      }
    };
    if (currentUser) {
      fetchShareholders();
    }
  }, [currentUser]);

  const getHolderName = (holder) => {
    // Return a fallback if holder or holder.names is missing or empty
    if (!holder || !Array.isArray(holder.names) || holder.names.length === 0) {
      return '(No Name)';
    }
    return holder.names.join(' / ');
  };

  const calculateAvailableRanges = (certificate) => {
    if (!certificate) return [];
    let ranges = [];
    if (certificate.distinctiveRanges && certificate.distinctiveRanges.length > 0) {
      ranges = [...certificate.distinctiveRanges].sort((a, b) => a.start - b.start);
    } else {
      ranges = [{
        start: certificate.distinctiveNumberStart,
        end: certificate.distinctiveNumberEnd
      }];
    }
    return ranges;
  };

  const onTransferTypeChange = (e) => {
    setTransferType(e.target.value);
    if (selectedCertificate) {
      if (e.target.value === 'full') {
        form.setFieldsValue({
          shareStart: selectedCertificate.distinctiveNumberStart,
          shareEnd: selectedCertificate.distinctiveNumberEnd
        });
      } else {
        const ranges = calculateAvailableRanges(selectedCertificate);
        if (ranges.length > 0) {
          form.setFieldsValue({ shareStart: ranges[0].start, shareEnd: null });
        } else {
          form.setFieldsValue({
            shareStart: selectedCertificate.distinctiveNumberStart,
            shareEnd: null
          });
        }
      }
    }
  };

  const onTransferorChange = async (value) => {
    try {
      const q = query(
        collection(db, 'shareholders'),
        where('folioNumber', '==', value),
        where('companyId', '==', currentUser.uid)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const shareholderDoc = querySnapshot.docs[0];
        const data = { id: shareholderDoc.id, ...shareholderDoc.data() };

        setShareholderData(data);
        setSelectedCertificate(null);
        setAvailableRanges([]);

        form.setFieldsValue({
          transferorName: getHolderName(data),
        });

        form.resetFields(['certificateNumber', 'shareStart', 'shareEnd']);
      } else {
        Modal.error({
          title: 'Error',
          content: 'Transferor not found',
        });
        setShareholderData(null);
        form.resetFields(['transferorName', 'certificateNumber', 'shareStart', 'shareEnd']);
      }
    } catch (error) {
      console.error('Error fetching transferor:', error);
      Modal.error({
        title: 'Error',
        content: 'Failed to fetch transferor details',
      });
    }
  };

  const onCertificateChange = (value) => {
    if (shareholderData && shareholderData.certificates) {
      const certificate = shareholderData.certificates.find(cert => cert.certificateNumber === value);
      if (certificate) {
        setSelectedCertificate(certificate);
        const ranges = calculateAvailableRanges(certificate);
        setAvailableRanges(ranges);

        if (transferType === 'full') {
          form.setFieldsValue({
            shareStart: certificate.distinctiveNumberStart,
            shareEnd: certificate.distinctiveNumberEnd
          });
        } else if (ranges.length > 0) {
          form.setFieldsValue({ shareStart: ranges[0].start, shareEnd: null });
        } else {
          form.setFieldsValue({
            shareStart: certificate.distinctiveNumberStart,
            shareEnd: null
          });
        }
      }
    }
  };

  const onTransfereeChange = (value) => {
    const existingTransferee = shareholders.find(sh => sh.folioNumber === value);
    if (existingTransferee) {
      setTransfereeExists(true);
      form.setFieldsValue({
        transfereeName: getHolderName(existingTransferee),
      });
    } else {
      setTransfereeExists(false);
      form.resetFields(['transfereeName']);
    }
  };

  const isInAvailableRanges = (start, end, ranges) => {
    return ranges.some(range => start >= range.start && end <= range.end);
  };

  /**
   * Retrieve and increment the next certificate sequence number from Firestore.
   * Store in a doc: 'certCounters/{companyId}' with a field 'lastCertificateNumber'.
   */
  const getNextCertificateSeq = async () => {
    if (!currentUser) throw new Error('No current user');
    const counterRef = doc(db, 'certCounters', currentUser.uid);
    const counterSnap = await getDoc(counterRef);
    let lastNum = 0;
    if (counterSnap.exists()) {
      const data = counterSnap.data();
      lastNum = data.lastCertificateNumber || 0;
    }
    const newNum = lastNum + 1;
    await updateDoc(counterRef, { lastCertificateNumber: newNum }).catch(async (err) => {
      // If doc doesn't exist, create it:
      if (err.code === 'not-found') {
        await setDoc(counterRef, { lastCertificateNumber: newNum });
      } else {
        throw err;
      }
    });
    return newNum;
  };

  const generateCertificateNumber = async (prefix) => {
    const seq = await getNextCertificateSeq();
    // Format seq as zero-padded number if desired, e.g. 5-digit padding
    const paddedSeq = seq.toString().padStart(5, '0');
    return `${prefix}-${COMPANY_INCORPORATION_YEAR}-${paddedSeq}`;
  };

  const handleConfirmOk = async () => {
    setConfirmLoading(true);
    try {
      const values = form.getFieldsValue();

      if (!selectedCertificate) {
        throw new Error('Please select a certificate');
      }

      const startValue = Number(values.shareStart);
      const endValue = Number(values.shareEnd);

      // Basic validation
      if (startValue > endValue) {
        throw new Error('Start number cannot be greater than end number');
      }

      if (startValue < selectedCertificate.distinctiveNumberStart ||
          endValue > selectedCertificate.distinctiveNumberEnd) {
        throw new Error('Selected range is outside the certificate bounds');
      }

      // For partial transfers, validate range availability
      if (transferType === 'partial' && !isInAvailableRanges(startValue, endValue, availableRanges)) {
        throw new Error('The chosen range is not available for partial transfer');
      }

      const sharesToTransfer = endValue - startValue + 1;

      // Fetch transferor and transferee
      const [transferorSnapshot, transfereeSnapshot] = await Promise.all([
        getDocs(query(
          collection(db, 'shareholders'),
          where('folioNumber', '==', values.transferorFolio),
          where('companyId', '==', currentUser.uid)
        )),
        getDocs(query(
          collection(db, 'shareholders'),
          where('folioNumber', '==', values.transfereeFolio),
          where('companyId', '==', currentUser.uid)
        ))
      ]);

      if (transferorSnapshot.empty) {
        throw new Error('Transferor not found');
      }

      const transferorDoc = transferorSnapshot.docs[0];
      const transferorData = transferorDoc.data();
      let transfereeDoc;
      let transfereeData;

      if (!transfereeSnapshot.empty) {
        transfereeDoc = transfereeSnapshot.docs[0];
        transfereeData = transfereeDoc.data();
      } else {
        // Create new transferee if not found
        const newTransfereeData = {
          folioNumber: values.transfereeFolio,
          names: [values.transfereeName],
          isJoint: false,
          certificates: [],
          totalShares: 0,
          status: 'Active',
          createdAt: serverTimestamp(),
          companyId: currentUser.uid
        };
        const newTransfereeRef = await addDoc(collection(db, 'shareholders'), newTransfereeData);
        transfereeDoc = await getDoc(newTransfereeRef);
        transfereeData = newTransfereeData;
      }

      const preTransferState = {
        transferorCertificates: [...(transferorData.certificates || [])],
        transfereeCertificates: [...(transfereeData.certificates || [])],
        transferorShares: transferorData.totalShares,
        transfereeShares: transfereeData.totalShares || 0
      };

      // Generate new certificate numbers with meaningful scheme
      const generateCancelledCertNumber = async (oldNumber) => {
        const seqNumber = await getNextCertificateSeq();
        const padded = seqNumber.toString().padStart(5,'0');
        return `CANCELLED-${COMPANY_INCORPORATION_YEAR}-${padded}-${oldNumber}`;
      };
      const generateSplitCertNumber = async (oldNumber) => {
        const seqNumber = await getNextCertificateSeq();
        const padded = seqNumber.toString().padStart(5,'0');
        return `SPLIT-${COMPANY_INCORPORATION_YEAR}-${padded}-${oldNumber}`;
      };

      // For simplicity, we fetch certificate numbers in sequence
      if (transferType === 'full') {
        const cancelledCertNumber = await generateCancelledCertNumber(selectedCertificate.certificateNumber);
        const newCertNumber = await generateCertificateNumber('NEW');

        // Update transferor certificates
        const updatedTransferorCertificates = (transferorData.certificates || []).map(cert => {
          if (cert.certificateNumber === selectedCertificate.certificateNumber) {
            return {
              ...cert,
              certificateNumber: cancelledCertNumber,
              status: 'Cancelled',
              cancellationDate: values.transferDate.toDate(),
              cancellationReason: 'Full Transfer'
            };
          }
          return cert;
        });

        // New certificate for transferee
        const newCertificate = {
          certificateNumber: newCertNumber,
          distinctiveNumberStart: startValue,
          distinctiveNumberEnd: endValue,
          totalShares: sharesToTransfer,
          status: 'Active',
          issueDate: values.transferDate.toDate(),
          previousCertificate: selectedCertificate.certificateNumber,
          previousOwner: values.transferorFolio,
          transferType: 'full'
        };

        await updateDoc(transferorDoc.ref, {
          certificates: updatedTransferorCertificates,
          totalShares: transferorData.totalShares - sharesToTransfer,
          status: updatedTransferorCertificates.some(cert => cert.status === 'Active') ? 'Active' : 'Inactive'
        });

        await updateDoc(transfereeDoc.ref, {
          certificates: [...(transfereeData.certificates || []), newCertificate],
          totalShares: (transfereeData.totalShares || 0) + sharesToTransfer
        });

      } else {
        // Partial transfer
        const cancelledCertNumber = await generateCancelledCertNumber(selectedCertificate.certificateNumber);
        const newTransferorCertNumber1 = await generateSplitCertNumber(selectedCertificate.certificateNumber);
        const newTransferorCertNumber2 = await generateSplitCertNumber(selectedCertificate.certificateNumber);
        const newTransfereeCertNumber = await generateCertificateNumber('NEW');

        const newTransferorCertificates = [];
        // If portion before transferred range
        if (startValue > selectedCertificate.distinctiveNumberStart) {
          newTransferorCertificates.push({
            certificateNumber: newTransferorCertNumber1,
            distinctiveNumberStart: selectedCertificate.distinctiveNumberStart,
            distinctiveNumberEnd: startValue - 1,
            totalShares: (startValue - selectedCertificate.distinctiveNumberStart),
            status: 'Active',
            issueDate: values.transferDate.toDate(),
            previousCertificate: selectedCertificate.certificateNumber,
            transferType: 'partial'
          });
        }

        // If portion after transferred range
        if (endValue < selectedCertificate.distinctiveNumberEnd) {
          newTransferorCertificates.push({
            certificateNumber: newTransferorCertNumber2,
            distinctiveNumberStart: endValue + 1,
            distinctiveNumberEnd: selectedCertificate.distinctiveNumberEnd,
            totalShares: (selectedCertificate.distinctiveNumberEnd - endValue),
            status: 'Active',
            issueDate: values.transferDate.toDate(),
            previousCertificate: selectedCertificate.certificateNumber,
            transferType: 'partial'
          });
        }

        // New certificate for transferee
        const newTransfereeCertificate = {
          certificateNumber: newTransfereeCertNumber,
          distinctiveNumberStart: startValue,
          distinctiveNumberEnd: endValue,
          totalShares: sharesToTransfer,
          status: 'Active',
          issueDate: values.transferDate.toDate(),
          previousCertificate: selectedCertificate.certificateNumber,
          previousOwner: values.transferorFolio,
          transferType: 'partial'
        };

        const updatedTransferorCertificates = (transferorData.certificates || [])
          .map(cert => {
            if (cert.certificateNumber === selectedCertificate.certificateNumber) {
              return {
                ...cert,
                certificateNumber: cancelledCertNumber,
                status: 'Cancelled',
                cancellationDate: values.transferDate.toDate(),
                cancellationReason: 'Partial Transfer'
              };
            }
            return cert;
          })
          .concat(newTransferorCertificates);

        await updateDoc(transferorDoc.ref, {
          certificates: updatedTransferorCertificates,
          totalShares: transferorData.totalShares - sharesToTransfer,
          status: 'Active'
        });

        await updateDoc(transfereeDoc.ref, {
          certificates: [...(transfereeData.certificates || []), newTransfereeCertificate],
          totalShares: (transfereeData.totalShares || 0) + sharesToTransfer
        });
      }

      // Record the transaction
      await addDoc(collection(db, 'transactions'), {
        transferNumber: values.transferNumber,
        transferorFolio: values.transferorFolio,
        transferorName: values.transferorName || '(No Name)',
        transfereeFolio: values.transfereeFolio,
        transfereeName: values.transfereeName || '(No Name)',
        certificateNumber: selectedCertificate.certificateNumber,
        shareStart: startValue,
        shareEnd: endValue,
        sharesToTransfer,
        transferDate: values.transferDate.toDate(),
        transferType,
        createdAt: serverTimestamp(),
        companyId: currentUser.uid,
        preTransferState: preTransferState,
        postTransferState: {
          transferorCertificates: transferorData.certificates,
          transfereeCertificates: transfereeData.certificates,
          transferorShares: transferorData.totalShares - sharesToTransfer,
          transfereeShares: (transfereeData.totalShares || 0) + sharesToTransfer
        }
      });

      form.resetFields();
      setTransferType('full');
      setShareholderData(null);
      setSelectedCertificate(null);
      setAvailableRanges([]);

      Modal.success({
        title: 'Success',
        content: 'Shares transferred successfully.',
      });
    } catch (error) {
      console.error('Error during transfer:', error);
      Modal.error({
        title: 'Error',
        content: error.message || 'Failed to transfer shares',
      });
    }
    setConfirmLoading(false);
    setConfirmVisible(false);
  };

  const handleNewShareholderOk = async (values) => {
    try {
      // Ensure a fallback name if not provided
      const newName = values.name && values.name.trim() !== '' ? values.name.trim() : '(No Name)';
      const newTransfereeData = {
        folioNumber: values.folioNumber,
        names: [newName],
        isJoint: false,
        certificates: [],
        totalShares: 0,
        status: 'Active',
        createdAt: serverTimestamp(),
        companyId: currentUser.uid
      };

      const newTransfereeRef = await addDoc(collection(db, 'shareholders'), newTransfereeData);

      setShareholders(prev => [...prev, {
        folioNumber: values.folioNumber,
        names: [newName],
      }]);

      form.setFieldsValue({
        transfereeFolio: values.folioNumber,
        transfereeName: newName,
      });

      setNewShareholderModalVisible(false);
      Modal.success({
        title: 'Success',
        content: 'New shareholder added successfully',
      });
    } catch (error) {
      console.error('Error adding new shareholder:', error);
      Modal.error({
        title: 'Error',
        content: 'Failed to add new shareholder',
      });
    }
  };

  return (
    <Card title="Transfer Shares" style={{ marginTop: '20px' }}>
      <Form
        form={form}
        name="transfer_shares"
        onFinish={() => setConfirmVisible(true)}
        layout="vertical"
      >
        <Form.Item
          label="Transfer Number"
          name="transferNumber"
          rules={[{ required: true, message: 'Please input transfer number!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Date of Transfer"
          name="transferDate"
          rules={[{ required: true, message: 'Please select transfer date!' }]}
        >
          <DatePicker
            style={{ width: '100%' }}
            disabledDate={(current) => current && current > moment().endOf('day')}
          />
        </Form.Item>

        <Form.Item
          label="Transferor Folio"
          name="transferorFolio"
          rules={[{ required: true, message: 'Please select transferor!' }]}
        >
          <Select
            showSearch
            placeholder="Select Transferor"
            optionFilterProp="children"
            onChange={onTransferorChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {shareholders.map((shareholder) => (
              <Option key={shareholder.folioNumber} value={shareholder.folioNumber}>
                {`${shareholder.folioNumber} - ${getHolderName(shareholder)} (${shareholder.totalShares?.toLocaleString() || 0} shares)`}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Transferor Name"
          name="transferorName"
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          label="Certificate Number"
          name="certificateNumber"
          rules={[{ required: true, message: 'Please select certificate number!' }]}
        >
          <Select
            placeholder="Select Certificate"
            onChange={onCertificateChange}
          >
            {shareholderData?.certificates?.map((cert) => (
              <Option key={cert.certificateNumber} value={cert.certificateNumber}>
                {`Certificate ${cert.certificateNumber} (${cert.distinctiveNumberStart?.toLocaleString()} - ${cert.distinctiveNumberEnd?.toLocaleString()}, ${cert.totalShares?.toLocaleString()} shares)`}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Transfer Type">
          <Radio.Group onChange={onTransferTypeChange} value={transferType}>
            <Radio value="full">Full Transfer (Entire Certificate)</Radio>
            <Radio value="partial">Partial Transfer (Range of Shares)</Radio>
          </Radio.Group>
        </Form.Item>

        {transferType === 'partial' && (
          <Alert
            message="Partial Transfer Rules"
            description="For partial transfers, the selected share range must be continuous and valid within the certificate's available ranges."
            type="info"
            showIcon
            style={{ marginBottom: 24 }}
          />
        )}

        <Form.Item
          label="Share Start Number"
          name="shareStart"
          rules={[
            { required: true, message: 'Please input share start number!' },
            ({ getFieldValue }) => ({
              validator: (_, value) => {
                if (!selectedCertificate || value == null) return Promise.resolve();
                const endValue = getFieldValue('shareEnd');
                if (value < selectedCertificate.distinctiveNumberStart) {
                  return Promise.reject(`Start number cannot be less than ${selectedCertificate.distinctiveNumberStart}`);
                }
                if (transferType === 'partial' && endValue && !isInAvailableRanges(value, endValue, availableRanges)) {
                  return Promise.reject('Selected start/end range is not valid for partial transfer');
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber
            style={{ width: '100%' }}
            disabled={transferType === 'full'}
            formatter={(val) => val?.toLocaleString()}
            parser={(val) => val?.replace(/\D/g, '')}
          />
        </Form.Item>

        <Form.Item
          label="Share End Number"
          name="shareEnd"
          rules={[
            { required: true, message: 'Please input share end number!' },
            ({ getFieldValue }) => ({
              validator: (_, value) => {
                if (!selectedCertificate || value == null) return Promise.resolve();
                const startValue = getFieldValue('shareStart');
                if (value < startValue) {
                  return Promise.reject('End number must be greater than start number');
                }
                if (value > selectedCertificate.distinctiveNumberEnd) {
                  return Promise.reject(`End number cannot exceed ${selectedCertificate.distinctiveNumberEnd}`);
                }
                if (transferType === 'partial' && !isInAvailableRanges(startValue, value, availableRanges)) {
                  return Promise.reject('Selected share range is not available for partial transfer');
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber
            style={{ width: '100%' }}
            disabled={transferType === 'full'}
            formatter={(val) => val?.toLocaleString()}
            parser={(val) => val?.replace(/\D/g, '')}
          />
        </Form.Item>

        <Form.Item
          label="Transferee Folio"
          name="transfereeFolio"
          rules={[{ required: true, message: 'Please input transferee folio!' }]}
        >
          <Select
            showSearch
            placeholder="Select Transferee"
            optionFilterProp="children"
            onChange={onTransfereeChange}
            notFoundContent={
              <Button type="link" onClick={() => setNewShareholderModalVisible(true)}>
                Transfer to New Shareholder?
              </Button>
            }
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {shareholders.map((shareholder) => (
              <Option key={shareholder.folioNumber} value={shareholder.folioNumber}>
                {`${shareholder.folioNumber} - ${getHolderName(shareholder)} (${shareholder.totalShares?.toLocaleString() || 0} shares)`}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Transferee Name"
          name="transfereeName"
          rules={[{ required: true, message: 'Please input transferee name!' }]}
        >
          <Input disabled={transfereeExists} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Transfer Shares
          </Button>
        </Form.Item>
      </Form>

      <Modal
        title="Confirm Share Transfer"
        visible={confirmVisible}
        onOk={handleConfirmOk}
        confirmLoading={confirmLoading}
        onCancel={() => setConfirmVisible(false)}
        okText="Confirm"
      >
        <p>Are you sure you want to transfer these shares? This will:</p>
        <ul>
          <li>Cancel the original share certificate</li>
          <li>Issue new certificates to both parties</li>
          <li>Record the transfer history</li>
        </ul>
      </Modal>

      <Modal
        title="Add New Shareholder"
        visible={newShareholderModalVisible}
        onCancel={() => setNewShareholderModalVisible(false)}
        footer={null}
      >
        <Form onFinish={handleNewShareholderOk} layout="vertical">
          <Form.Item
            label="Folio Number"
            name="folioNumber"
            rules={[{ required: true, message: 'Please input folio number!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Shareholder Name"
            name="name"
            rules={[{ required: true, message: 'Please input name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Add Shareholder
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  );
};

export default TransferShares;