import React, { useContext, useEffect, useState } from 'react';
import { db } from '../../firebase';
import { 
  collection, 
  getDocs, 
  doc, 
  deleteDoc, 
  query, 
  where,
  updateDoc,
  getDoc,
  addDoc
} from 'firebase/firestore';
import { Table, Card, Typography, Button, Modal, Space, Tag, Tooltip, Alert } from 'antd';
import moment from 'moment';
import { 
  WarningOutlined,
  CheckCircleOutlined,
  InfoCircleOutlined,
  RollbackOutlined
} from '@ant-design/icons';
import { AuthContext } from '../../contexts/AuthContext';

const { Text, Paragraph } = Typography;
const { confirm } = Modal;

const TransactionsList = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reversalDetails, setReversalDetails] = useState(null);
  const [reversalModalVisible, setReversalModalVisible] = useState(false);
  const { currentUser } = useContext(AuthContext);

  const fetchTransactions = async () => {
    try {
      if (!currentUser) return;
      const transactionsRef = collection(db, 'transactions');
      const q = query(transactionsRef, where("companyId", "==", currentUser.uid));
      const querySnapshot = await getDocs(q);
      const data = querySnapshot.docs.map((docSnap) => ({
        key: docSnap.id,
        ...docSnap.data(),
      }));
      setTransactions(data);
    } catch (error) {
      console.error('Error fetching transactions:', error);
      Modal.error({
        title: 'Error',
        content: 'Failed to fetch transactions',
      });
    }
  };

  useEffect(() => {
    if (currentUser) {
      fetchTransactions();
    }
  }, [currentUser]);

  const showReversalDetails = (record) => {
    setReversalDetails(record);
    setReversalModalVisible(true);
  };

  const handleReverse = async (record) => {
    confirm({
      title: 'Reverse Transaction',
      icon: <WarningOutlined style={{ color: '#ff4d4f' }} />,
      content: (
        <div>
          <Paragraph>
            This will reverse transaction <Text strong>{record.transferNumber}</Text> and:
          </Paragraph>
          <ul>
            <li>Cancel all certificates created during this transfer</li>
            <li>Restore original certificates to their previous state</li>
            <li>Update share counts for both parties</li>
            <li>Record the reversal in the transaction history</li>
          </ul>
          <Alert
            message="This action cannot be undone"
            type="warning"
            showIcon
            style={{ marginTop: 16 }}
          />
        </div>
      ),
      okText: 'Proceed with Reversal',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: async () => {
        setLoading(true);
        try {
          if (!record.preTransferState) {
            throw new Error('No preTransferState available. Cannot reverse this transaction.');
          }
          if (!record.transferorFolio || !record.transfereeFolio) {
            throw new Error('Missing transferor or transferee folio. Cannot reverse.');
          }

          // Fetch Transferor Document
          const transferorQ = query(
            collection(db, 'shareholders'),
            where('folioNumber', '==', record.transferorFolio),
            where('companyId', '==', currentUser.uid)
          );
          const transferorSnap = await getDocs(transferorQ);
          if (transferorSnap.empty) {
            throw new Error('Transferor not found for reversal');
          }
          const transferorRef = transferorSnap.docs[0].ref;
          const transferorData = transferorSnap.docs[0].data();

          // Fetch Transferee Document
          const transfereeQ = query(
            collection(db, 'shareholders'),
            where('folioNumber', '==', record.transfereeFolio),
            where('companyId', '==', currentUser.uid)
          );
          const transfereeSnap = await getDocs(transfereeQ);
          if (transfereeSnap.empty) {
            throw new Error('Transferee not found for reversal');
          }
          const transfereeRef = transfereeSnap.docs[0].ref;
          const transfereeData = transfereeSnap.docs[0].data();

          const pre = record.preTransferState;
          // Restore pre-transfer state for both parties
          await updateDoc(transferorRef, {
            certificates: pre.transferorCertificates,
            totalShares: pre.transferorShares,
            status: pre.transferorCertificates.some(c => c.status === 'Active') ? 'Active' : 'Inactive'
          });

          await updateDoc(transfereeRef, {
            certificates: pre.transfereeCertificates,
            totalShares: pre.transfereeShares,
            status: pre.transfereeCertificates.some(c => c.status === 'Active') ? 'Active' : 'Inactive'
          });

          // Add a new reversal transaction record
          const reversalRef = await addDoc(collection(db, 'transactions'), {
            transferNumber: `REV-${record.transferNumber}`,
            originalTransfer: record.transferNumber,
            transferorFolio: record.transfereeFolio,
            transferorName: record.transfereeName,
            transfereeFolio: record.transferorFolio,
            transfereeName: record.transferorName,
            // You can decide what certificateNumber/newCertificateNumber to store here.
            // We'll just reference the original certificateNumber from the original transfer.
            certificateNumber: record.newCertificateNumber || record.certificateNumber,
            newCertificateNumber: null,
            shareStart: record.shareStart,
            shareEnd: record.shareEnd,
            sharesToTransfer: record.sharesToTransfer,
            transferDate: new Date(),
            transferType: 'reversal',
            createdAt: new Date(),
            companyId: currentUser.uid,
            // Pre-transfer state of reversal is the post-transfer state of the original transaction
            preTransferState: {
              transferorCertificates: transfereeData.certificates,
              transfereeCertificates: transferorData.certificates,
              transferorShares: transfereeData.totalShares,
              transfereeShares: transferorData.totalShares
            },
            // Post-transfer state after reversal is the preTransferState of the original transaction
            postTransferState: {
              transferorCertificates: pre.transferorCertificates,
              transfereeCertificates: pre.transfereeCertificates,
              transferorShares: pre.transferorShares,
              transfereeShares: pre.transfereeShares
            }
          });

          // If you want, you could also delete the original transaction:
          // await deleteDoc(doc(db, 'transactions', record.key));

          Modal.success({
            title: 'Success',
            content: 'Transaction reversed successfully.',
          });

          fetchTransactions();
        } catch (error) {
          console.error('Error reversing transaction:', error);
          Modal.error({
            title: 'Error',
            content: error.message || 'Failed to reverse transaction',
          });
        } finally {
          setLoading(false);
        }
      },
    });
  };

  const columns = [
    {
      title: 'Transfer Number',
      dataIndex: 'transferNumber',
      key: 'transferNumber',
      render: (text, record) => (
        <Space>
          <Text>{text}</Text>
          {record.transferType === 'reversal' && (
            <Tag color="orange">Reversal</Tag>
          )}
        </Space>
      ),
      sorter: (a, b) => a.transferNumber?.localeCompare(b.transferNumber),
    },
    {
      title: 'Date',
      dataIndex: 'transferDate',
      key: 'transferDate',
      render: (date) => date && date.toDate 
        ? moment(date.toDate()).format('DD/MM/YYYY') 
        : 'N/A',
      sorter: (a, b) => {
        const aTime = a.transferDate?.toDate ? a.transferDate.toDate().getTime() : 0;
        const bTime = b.transferDate?.toDate ? b.transferDate.toDate().getTime() : 0;
        return aTime - bTime;
      },
    },
    {
      title: 'From',
      dataIndex: 'transferorName',
      key: 'transferorName',
      render: (_, record) => (
        <Space direction="vertical" size={0}>
          <Text>{record.transferorName || '(No Name)'}</Text>
          <Text type="secondary">{record.transferorFolio || '(No Folio)'}</Text>
        </Space>
      ),
    },
    {
      title: 'To',
      dataIndex: 'transfereeName',
      key: 'transfereeName',
      render: (_, record) => (
        <Space direction="vertical" size={0}>
          <Text>{record.transfereeName || '(No Name)'}</Text>
          <Text type="secondary">{record.transfereeFolio || '(No Folio)'}</Text>
        </Space>
      ),
    },
    {
      title: 'Shares',
      dataIndex: 'sharesToTransfer',
      key: 'sharesToTransfer',
      render: (value) => value ? value.toLocaleString() : '0',
    },
    {
      title: 'Type',
      key: 'type',
      render: (_, record) => {
        if (record.transferType === 'reversal') {
          return <Tag color="orange">Reversal</Tag>;
        }
        return (
          <Tag color={record.transferType === 'full' ? 'blue' : 'purple'}>
            {record.transferType === 'full' ? 'Full' : 'Partial'}
          </Tag>
        );
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Tooltip title="View Details">
            <Button 
              type="text"
              icon={<InfoCircleOutlined />}
              onClick={() => showReversalDetails(record)}
            />
          </Tooltip>
          {record.transferType !== 'reversal' && (
            <Tooltip title="Reverse Transaction">
              <Button 
                type="text" 
                danger
                icon={<RollbackOutlined />}
                onClick={() => handleReverse(record)}
                loading={loading}
              />
            </Tooltip>
          )}
        </Space>
      ),
    }
  ];

  const certificateColumns = [
    {
      title: 'Certificate Number',
      dataIndex: 'certificateNumber',
      key: 'certificateNumber',
      width: '25%',
      render: (text, record) => (
        <Space direction="vertical" size={0}>
          <Text>{text || '(No Certificate)'}</Text>
          <Space size={4}>
            {record.status === 'Active' && (
              <Tag icon={<CheckCircleOutlined />} color="success">Active</Tag>
            )}
            {record.previousCertificate && (
              <Tag color="blue">Previous: {record.previousCertificate}</Tag>
            )}
          </Space>
        </Space>
      ),
    },
    {
      title: 'Distinctive Numbers',
      key: 'distinctiveNumbers',
      width: '30%',
      render: (_, record) => {
        const startNum = record.distinctiveNumberStart || 0;
        const endNum = record.distinctiveNumberEnd || 0;
        return <Text>{startNum.toLocaleString()} - {endNum.toLocaleString()}</Text>;
      },
    },
    {
      title: 'Shares',
      key: 'totalShares',
      width: '20%',
      render: (_, record) => (record.totalShares || 0).toLocaleString(),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '25%',
      render: (status) => (
        <Tag color={status === 'Active' ? 'success' : 'error'}>
          {status}
        </Tag>
      ),
    }
  ];

  const expandedRowRender = (record) => {
    const certNumber = record.certificateNumber || '(No Certificate)';
    const prevCert = record.previousCertificate || null;
    const startVal = record.shareStart || 0;
    const endVal = record.shareEnd || 0;
    const totalVal = record.sharesToTransfer || 0;

    return (
      <div style={{ margin: '0 48px' }}>
        <Paragraph>
          <Text strong>Certificate Details:</Text>
        </Paragraph>
        <Table
          columns={certificateColumns}
          dataSource={[
            {
              certificateNumber: certNumber,
              previousCertificate: prevCert,
              distinctiveNumberStart: startVal,
              distinctiveNumberEnd: endVal,
              totalShares: totalVal,
              status: 'Active'
            }
          ]}
          pagination={false}
          size="small"
        />
      </div>
    );
  };

  return (
    <Card title="Transactions List" style={{ marginTop: '20px' }}>
      <Table 
        columns={columns} 
        dataSource={transactions}
        expandable={{
          expandedRowRender,
          expandRowByClick: true,
        }}
        loading={loading}
      />

      <Modal
        title="Transaction Details"
        visible={reversalModalVisible}
        onCancel={() => {
          setReversalModalVisible(false);
          setReversalDetails(null);
        }}
        footer={null}
        width={800}
      >
        {reversalDetails && (
          <div>
            <Space direction="vertical" size={16} style={{ width: '100%' }}>
              <Card size="small" title="Transfer Information">
                <div style={{ marginBottom: '16px' }}>
                  <Text strong>Transfer Number: </Text>
                  <Text>{reversalDetails.transferNumber || '(No Number)'}</Text>
                </div>
                <div style={{ marginBottom: '16px' }}>
                  <Text strong>Date: </Text>
                  <Text>
                    {reversalDetails.transferDate && reversalDetails.transferDate.toDate
                      ? moment(reversalDetails.transferDate.toDate()).format('DD/MM/YYYY')
                      : 'N/A'}
                  </Text>
                </div>
                <div style={{ marginBottom: '16px' }}>
                  <Text strong>Type: </Text>
                  <Tag color={reversalDetails.transferType === 'full' ? 'blue' : reversalDetails.transferType === 'partial' ? 'purple' : 'orange'}>
                    {reversalDetails.transferType === 'reversal'
                      ? 'Reversal'
                      : reversalDetails.transferType === 'full'
                        ? 'Full Transfer'
                        : 'Partial Transfer'}
                  </Tag>
                </div>
                <div>
                  <Text strong>Shares: </Text>
                  <Text>{(reversalDetails.sharesToTransfer || 0).toLocaleString()}</Text>
                </div>
              </Card>

              <Card size="small" title="Transferor Details">
                <div style={{ marginBottom: '16px' }}>
                  <Text strong>Name: </Text>
                  <Text>{reversalDetails.transferorName || '(No Name)'}</Text>
                </div>
                <div style={{ marginBottom: '16px' }}>
                  <Text strong>Folio Number: </Text>
                  <Text>{reversalDetails.transferorFolio || '(No Folio)'}</Text>
                </div>
                <div>
                  <Text strong>Original Certificate: </Text>
                  <Text>{reversalDetails.certificateNumber || '(No Certificate)'}</Text>
                  {reversalDetails.previousCertificate && (
                    <Tag color="blue" style={{ marginLeft: 8 }}>
                      Previous: {reversalDetails.previousCertificate}
                    </Tag>
                  )}
                </div>
              </Card>

              <Card size="small" title="Transferee Details">
                <div style={{ marginBottom: '16px' }}>
                  <Text strong>Name: </Text>
                  <Text>{reversalDetails.transfereeName || '(No Name)'}</Text>
                </div>
                <div style={{ marginBottom: '16px' }}>
                  <Text strong>Folio Number: </Text>
                  <Text>{reversalDetails.transfereeFolio || '(No Folio)'}</Text>
                </div>
                <div>
                  <Text strong>New Certificate: </Text>
                  <Text>{reversalDetails.newCertificateNumber || '(No New Certificate)'}</Text>
                  <Tag color="green" style={{ marginLeft: 8 }}>Active</Tag>
                </div>
              </Card>

              <Card size="small" title="Share Details">
                <div style={{ marginBottom: '16px' }}>
                  <Text strong>Distinctive Numbers: </Text>
                  <Text>
                    {(reversalDetails.shareStart || 0).toLocaleString()} - {(reversalDetails.shareEnd || 0).toLocaleString()}
                  </Text>
                </div>
                <div>
                  <Text strong>Total Shares: </Text>
                  <Text>{(reversalDetails.sharesToTransfer || 0).toLocaleString()}</Text>
                </div>
              </Card>

              {reversalDetails.transferType === 'reversal' && (
                <Alert
                  message="Reversal Transaction"
                  description={
                    <Space direction="vertical">
                      <Text>This is a reversal of transfer: <Text strong>{reversalDetails.originalTransfer || '(No Original Transfer)'}</Text></Text>
                      <Text>
                        Reversal Date: {reversalDetails.transferDate && reversalDetails.transferDate.toDate
                          ? moment(reversalDetails.transferDate.toDate()).format('DD/MM/YYYY')
                          : 'N/A'}
                      </Text>
                    </Space>
                  }
                  type="warning"
                  showIcon
                />
              )}

              {reversalDetails.transferType !== 'reversal' && (
                <Button
                  type="primary"
                  danger
                  icon={<RollbackOutlined />}
                  onClick={() => {
                    setReversalModalVisible(false);
                    handleReverse(reversalDetails);
                  }}
                  loading={loading}
                >
                  Reverse This Transaction
                </Button>
              )}
            </Space>
          </div>
        )}
      </Modal>
    </Card>
  );
};

export default TransactionsList;