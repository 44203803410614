// src/pages/legal/Security.js

import React from 'react';
import { Typography, Breadcrumb, Layout } from 'antd';
import { Link } from 'react-router-dom';
import AppFooter from '../../components/Layout/Footer';
import LandingNav from '../../components/Layout/LandingNav';
import '../../styles/Legal.css';

const { Title, Paragraph, Text } = Typography;
const { Content } = Layout;

const Security = () => {
  return (
    <Layout className="legal-page-layout">
      <LandingNav title="lumioCapital" />
      
      <Content>
        <div className="legal-container">
          <div className="legal-content">
            <div className="legal-navigation">
              <Breadcrumb>
                <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item>Security</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="legal-header">
              <Title level={1}>Security Policy</Title>
              <Text className="last-updated">Last Updated: December 5, 2024</Text>
            </div>

            <div className="legal-section">
              <section className="legal-introduction">
                <Paragraph>
                  This Security Policy outlines the security measures and practices implemented by Lumiotech Private Limited to protect user data and ensure secure operation of the lumioCapital platform.
                </Paragraph>
              </section>

              <section>
                <Title level={2}>1. Infrastructure Security</Title>
                
                <Title level={3}>1.1 Cloud Infrastructure</Title>
                <Paragraph>
                  Our platform is hosted on enterprise-grade cloud infrastructure with:
                </Paragraph>
                <ul>
                  <li>ISO 27001 certified data centers</li>
                  <li>Redundant systems and backups</li>
                  <li>24/7 infrastructure monitoring</li>
                  <li>Geographic data redundancy</li>
                </ul>

                <Title level={3}>1.2 Network Security</Title>
                <Paragraph>
                  We implement multiple layers of network security:
                </Paragraph>
                <ul>
                  <li>Enterprise-grade firewalls</li>
                  <li>DDoS protection</li>
                  <li>Network segregation</li>
                  <li>Regular security audits</li>
                  <li>Intrusion detection systems</li>
                </ul>
              </section>

              <section>
                <Title level={2}>2. Data Security</Title>

                <Title level={3}>2.1 Encryption</Title>
                <Paragraph>
                  All data is protected using:
                </Paragraph>
                <ul>
                  <li>TLS 1.3 for data in transit</li>
                  <li>AES-256 encryption for data at rest</li>
                  <li>End-to-end encryption for sensitive communications</li>
                  <li>Secure key management systems</li>
                </ul>

                <Title level={3}>2.2 Data Access</Title>
                <Paragraph>
                  Access to data is controlled through:
                </Paragraph>
                <ul>
                  <li>Role-based access control (RBAC)</li>
                  <li>Principle of least privilege</li>
                  <li>Regular access reviews</li>
                  <li>Audit logging of all access</li>
                </ul>
              </section>

              <section>
                <Title level={2}>3. Application Security</Title>
                
                <Title level={3}>3.1 Development Practices</Title>
                <Paragraph>
                  Our development process includes:
                </Paragraph>
                <ul>
                  <li>Secure code reviews</li>
                  <li>Regular security testing</li>
                  <li>Vulnerability scanning</li>
                  <li>Third-party security audits</li>
                </ul>

                <Title level={3}>3.2 Security Features</Title>
                <Paragraph>
                  The platform implements:
                </Paragraph>
                <ul>
                  <li>Multi-factor authentication</li>
                  <li>Session management</li>
                  <li>Brute force protection</li>
                  <li>Input validation</li>
                  <li>XSS and CSRF protection</li>
                </ul>
              </section>

              <section>
                <Title level={2}>4. User Account Security</Title>
                
                <Title level={3}>4.1 Authentication</Title>
                <Paragraph>
                  User accounts are secured through:
                </Paragraph>
                <ul>
                  <li>Strong password requirements</li>
                  <li>Multi-factor authentication</li>
                  <li>Regular session timeouts</li>
                  <li>Login attempt monitoring</li>
                </ul>

                <Title level={3}>4.2 User Responsibilities</Title>
                <Paragraph>
                  Users must:
                </Paragraph>
                <ul>
                  <li>Maintain strong, unique passwords</li>
                  <li>Enable 2FA when available</li>
                  <li>Report suspicious activities</li>
                  <li>Follow security best practices</li>
                </ul>
              </section>

              <section>
                <Title level={2}>5. Operational Security</Title>

                <Title level={3}>5.1 Monitoring</Title>
                <Paragraph>
                  We maintain continuous monitoring of:
                </Paragraph>
                <ul>
                  <li>System performance</li>
                  <li>Security events</li>
                  <li>User activities</li>
                  <li>Infrastructure health</li>
                </ul>

                <Title level={3}>5.2 Incident Response</Title>
                <Paragraph>
                  Our incident response includes:
                </Paragraph>
                <ul>
                  <li>24/7 response team</li>
                  <li>Documented procedures</li>
                  <li>Regular drills and testing</li>
                  <li>Post-incident analysis</li>
                </ul>
              </section>

              <section>
                <Title level={2}>6. Compliance and Auditing</Title>
                <Paragraph>
                  Our security program includes:
                </Paragraph>
                <ul>
                  <li>Regular security assessments</li>
                  <li>Compliance audits</li>
                  <li>Penetration testing</li>
                  <li>Security certifications</li>
                  <li>Regulatory compliance</li>
                </ul>
              </section>

              <section>
                <Title level={2}>7. Vendor Security</Title>
                <Paragraph>
                  We ensure security in our supply chain through:
                </Paragraph>
                <ul>
                  <li>Vendor security assessments</li>
                  <li>Security requirements in contracts</li>
                  <li>Regular vendor reviews</li>
                  <li>Third-party risk management</li>
                </ul>
              </section>

              <section>
                <Title level={2}>8. Security Updates</Title>
                <Paragraph>
                  We maintain security through:
                </Paragraph>
                <ul>
                  <li>Regular system updates</li>
                  <li>Security patch management</li>
                  <li>Vulnerability management</li>
                  <li>Continuous improvement</li>
                </ul>
              </section>

              <section>
                <Title level={2}>9. Reporting Security Issues</Title>
                <Paragraph>
                  If you discover a security issue:
                </Paragraph>
                <ul>
                  <li>Report immediately to our security team</li>
                  <li>Do not disclose to third parties</li>
                  <li>Provide detailed information</li>
                  <li>Cooperate with investigation</li>
                </ul>
              </section>

              <section>
                <Title level={2}>10. Disclaimers</Title>
                <Paragraph>
                  While we implement comprehensive security measures:
                </Paragraph>
                <ul>
                  <li>No system is completely secure</li>
                  <li>Users are responsible for their account security</li>
                  <li>We cannot guarantee against all threats</li>
                  <li>Security is a shared responsibility</li>
                </ul>
              </section>

              <section>
                <Title level={2}>11. Contact Information</Title>
                <div className="legal-contact">
                  <Title level={3}>Security Team Contact</Title>
                  <Paragraph>
                    For security-related inquiries or reports:<br />
                    Security Team<br />
                    Email: dpo@lumiotech.in
                  </Paragraph>
                </div>
              </section>
            </div>
          </div>
        </div>
      </Content>

      <AppFooter />
    </Layout>
  );
};

export default Security;