import React, { useState } from 'react';
import { Tabs, Typography } from 'antd';
import { UserAddOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import AddShareholder from '../components/Shareholders/AddShareholder';
import ShareholdersList from '../components/Shareholders/ShareholdersList';

const { Title, Text } = Typography;

const ShareholdersPage = () => {
  const [activeKey, setActiveKey] = useState('1');

  const onTabChange = (key) => {
    setActiveKey(key);
  };

  return (
    <div className="page-container">
      {/* Header Section */}
      <div className="shareholders-header">
        <div className="header-content">
          <Title level={2} className="page-title">Shareholders</Title>
          <Text className="page-subtitle">Manage shareholders and share certificates</Text>
        </div>
      </div>

      {/* Main Content */}
      <div className="shareholders-content">
        <Tabs 
          activeKey={activeKey}
          onChange={onTabChange}
          type="card"
          size="large"
          className="custom-tabs"
          items={[
            {
              key: '1',
              label: (
                <span className="tab-label">
                  <UserAddOutlined />
                  Add Shareholder
                </span>
              ),
              children: <AddShareholder />
            },
            {
              key: '2',
              label: (
                <span className="tab-label">
                  <UsergroupAddOutlined />
                  View Shareholders
                </span>
              ),
              children: <ShareholdersList />
            }
          ]}
        />
      </div>

      <style jsx>{`
        .page-container {
          padding: 32px;
          background: #ffffff;
          min-height: calc(100vh - 112px);
          animation: fadeIn 0.6s ease-out;
          margin: 0;
          padding-top: 150px;
        }

        .shareholders-header {
          margin-bottom: 32px;
          animation: slideUp 0.6s ease-out;
        }

        .header-content {
          background: var(--surface-color);
          padding: 32px;
          border-radius: 16px;
          box-shadow: var(--shadow-md);
          border: 1px solid var(--border-color);
          transition: all 0.3s ease;
        }

        .header-content:hover {
          box-shadow: var(--shadow-lg);
        }

        .page-title {
          color: var(--heading-color);
          margin: 0 !important;
          font-size: 32px !important;
          font-weight: 700 !important;
          letter-spacing: -0.02em;
          line-height: 1.2;
        }

        .page-subtitle {
          color: var(--text-light);
          font-size: 16px;
          margin-top: 8px;
          display: block;
        }

        .shareholders-content {
          animation: slideUp 0.6s ease-out 0.2s both;
        }

        .custom-tabs {
          background: var(--surface-color);
          padding: 24px;
          border-radius: 16px;
          box-shadow: var(--shadow-md);
          border: 1px solid var(--border-color);
        }

        .tab-label {
          display: flex;
          align-items: center;
          gap: 8px;
          font-size: 15px;
          font-weight: 500;
        }

        @keyframes fadeIn {
          from { opacity: 0; }
          to { opacity: 1; }
        }

        @keyframes slideUp {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        @media (max-width: 768px) {
          .page-container {
            padding: 16px;
            padding-top: 120px;
          }

          .header-content {
            padding: 24px;
          }

          .page-title {
            font-size: 28px !important;
          }

          .custom-tabs {
            padding: 16px;
          }
        }
      `}</style>
    </div>
  );
};

export default ShareholdersPage;