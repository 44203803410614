// src/utils/validation.js

// Regular expression for Indian phone numbers
// Matches formats:
// - 10 digits starting with 6-9
// - Optional +91 prefix
// - Optional 0 or 91 prefix
export const phoneRegex = /^(?:(?:\+|0{0,2})91(\s*[-]\s*)?|[0]?)?[6789]\d{9}$/;

// Regular expression for email validation
// Follows standard email format guidelines
export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

// Phone number validator for form fields
export const validatePhone = (_, value) => {
  if (!value) {
    return Promise.reject('Please input phone number!');
  }
  if (!phoneRegex.test(value)) {
    return Promise.reject('Please enter a valid Indian phone number!');
  }
  return Promise.resolve();
};

// Email validator for form fields
export const validateEmail = (_, value) => {
  if (!value) {
    return Promise.reject('Please input email address!');
  }
  if (!emailRegex.test(value)) {
    return Promise.reject('Please enter a valid email address!');
  }
  return Promise.resolve();
};

// Helper function to format phone numbers
export const formatPhoneNumber = (phoneNumber) => {
  // Remove all non-digit characters
  const cleaned = phoneNumber.replace(/\D/g, '');
  
  // Check if it starts with country code
  if (cleaned.startsWith('91') && cleaned.length > 10) {
    return `+91 ${cleaned.slice(2)}`;
  }
  
  return cleaned;
};

// Helper function to validate email format without promise
export const isValidEmail = (email) => {
  return emailRegex.test(email);
};

// Helper function to validate phone format without promise
export const isValidPhone = (phone) => {
  return phoneRegex.test(phone);
};