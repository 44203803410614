// src/firebase/index.js

import firebaseApp from './config';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);

export { auth, db };
