import React, { useState, useEffect, useContext } from 'react';
import { Tabs, Typography, Spin } from 'antd';
import { SwapOutlined, HistoryOutlined } from '@ant-design/icons';
import TransferShares from '../components/Transactions/TransferShares';
import TransactionsList from '../components/Transactions/TransactionsList';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { AuthContext } from '../contexts/AuthContext';
import moment from 'moment';

const { Title, Text } = Typography;

const TransactionsPage = () => {
  const [activeKey, setActiveKey] = useState('1');
  const [transactionStats, setTransactionStats] = useState({
    total: 0,
    currentMonth: 0
  });
  const [loading, setLoading] = useState(true);
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const fetchTransactionStats = async () => {
      if (!currentUser) return;
      
      try {
        const transactionsRef = collection(db, 'transactions');
        const q = query(transactionsRef, where("companyId", "==", currentUser.uid));
        const querySnapshot = await getDocs(q);
        
        const currentMonth = moment().format('MMMM YYYY');
        const startOfMonth = moment().startOf('month');
        
        const transactions = querySnapshot.docs.map(doc => ({
          ...doc.data(),
          transferDate: doc.data().transferDate?.toDate()
        }));

        const monthlyTransactions = transactions.filter(
          t => moment(t.transferDate).isAfter(startOfMonth)
        );

        setTransactionStats({
          total: transactions.length,
          currentMonth: monthlyTransactions.length
        });
      } catch (error) {
        console.error('Error fetching transaction stats:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactionStats();
  }, [currentUser]);

  const onTabChange = (key) => {
    setActiveKey(key);
  };

  return (
    <div className="page-container">
      <div className="transactions-header">
        <div className="header-content">
          <div className="header-main">
            <Title level={2} className="page-title">Share Transactions</Title>
            <Text className="page-subtitle">Manage share transfers and view transaction history</Text>
          </div>
          {loading ? (
            <div className="stats-loading">
              <Spin size="large" />
            </div>
          ) : (
            <div className="header-stats">
              <div className="stat-item">
                <Text className="stat-label">Total Transfers</Text>
                <Text className="stat-value">{transactionStats.total}</Text>
              </div>
              <div className="stat-item">
                <Text className="stat-label">{moment().format('MMMM YYYY')}</Text>
                <Text className="stat-value">{transactionStats.currentMonth}</Text>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="transactions-content">
        <Tabs 
          activeKey={activeKey}
          onChange={onTabChange}
          type="card"
          size="large"
          className="custom-tabs"
          items={[
            {
              key: '1',
              label: (
                <span className="tab-label">
                  <SwapOutlined />
                  Transfer Shares
                </span>
              ),
              children: (
                <div className="tab-content">
                  <TransferShares />
                </div>
              )
            },
            {
              key: '2',
              label: (
                <span className="tab-label">
                  <HistoryOutlined />
                  Transaction History
                </span>
              ),
              children: (
                <div className="tab-content">
                  <TransactionsList />
                </div>
              )
            }
          ]}
        />
      </div>

      <style jsx>{`
        .page-container {
          padding: 32px;
          background: #ffffff;
          min-height: calc(100vh - 112px);
          animation: fadeIn 0.6s ease-out;
          margin: 0;
          padding-top: 150px;
        }

        .transactions-header {
          margin-bottom: 32px;
          animation: slideUp 0.6s ease-out;
        }

        .header-content {
          background: var(--surface-color);
          padding: 32px;
          border-radius: 16px;
          box-shadow: var(--shadow-md);
          border: 1px solid var(--border-color);
          transition: all 0.3s ease;
        }

        .header-content:hover {
          box-shadow: var(--shadow-lg);
        }

        .header-main {
          margin-bottom: 24px;
        }

        .page-title {
          color: var(--heading-color);
          margin: 0 !important;
          font-size: 32px !important;
          font-weight: 700 !important;
          letter-spacing: -0.02em;
          line-height: 1.2;
        }

        .page-subtitle {
          color: var(--text-light);
          font-size: 16px;
          margin-top: 8px;
          display: block;
        }

        .stats-loading {
          display: flex;
          justify-content: center;
          padding: 40px 0;
        }

        .header-stats {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
          gap: 24px;
          margin-top: 24px;
          padding-top: 24px;
          border-top: 1px solid var(--border-color);
        }

        .stat-item {
          display: flex;
          flex-direction: column;
          gap: 8px;
          padding: 20px;
          background: var(--surface-hover);
          border-radius: 12px;
          border: 1px solid var(--border-color);
          transition: all 0.3s ease;
        }

        .stat-item:hover {
          transform: translateY(-2px);
          box-shadow: var(--shadow-sm);
          border-color: var(--secondary-color);
        }

        .stat-label {
          color: var(--text-light);
          font-size: 14px;
          font-weight: 500;
        }

        .stat-value {
          color: var(--heading-color);
          font-size: 24px;
          font-weight: 600;
        }

        .transactions-content {
          animation: slideUp 0.6s ease-out 0.2s both;
        }

        .custom-tabs {
          background: var(--surface-color);
          padding: 24px;
          border-radius: 16px;
          box-shadow: var(--shadow-md);
          border: 1px solid var(--border-color);
        }

        .tab-label {
          display: flex;
          align-items: center;
          gap: 8px;
          font-size: 15px;
          font-weight: 500;
        }

        .tab-content {
          margin-top: 24px;
        }

        @keyframes fadeIn {
          from { opacity: 0; }
          to { opacity: 1; }
        }

        @keyframes slideUp {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        @media (max-width: 768px) {
          .page-container {
            padding: 16px;
            padding-top: 120px;
          }

          .header-content {
            padding: 24px;
          }

          .page-title {
            font-size: 28px !important;
          }

          .header-stats {
            grid-template-columns: 1fr;
            gap: 16px;
          }

          .stat-item {
            padding: 16px;
          }

          .custom-tabs {
            padding: 16px;
          }
        }
      `}</style>
    </div>
  );
};

export default TransactionsPage;