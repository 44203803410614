// src/utils/cinValidator.js

// Lists of valid state codes and company types
const stateCodes = [
    'AP', 'AR', 'AS', 'BR', 'CG', 'GA', 'GJ', 'HR', 'HP', 'JK',
    'JH', 'KA', 'KL', 'MP', 'MH', 'MN', 'ML', 'MZ', 'NL', 'OR',
    'PB', 'RJ', 'SK', 'TN', 'TS', 'TR', 'UP', 'UK', 'WB', 'AN',
    'CH', 'DN', 'DD', 'DL', 'LD', 'PY',
  ];
  
  const companyTypes = [
    'PLC', 'SGC', 'NPL', 'OPC', 'FTC', 'GAP',
    'GOI', 'PTC', 'ULL', 'ULT',
  ];
  
  // CIN validation function
  export const validateCIN = (_, value) => {
    if (!value) {
      return Promise.reject(new Error('Please input your CIN!'));
    }
  
    const cinRegex = /^[LU][0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$/i;
  
    if (!cinRegex.test(value)) {
      return Promise.reject(new Error('Please enter a valid CIN in the correct format.'));
    }
  
    // Corrected indices for state code and company type
    const stateCode = value.substring(6, 8).toUpperCase();
    const companyType = value.substring(12, 15).toUpperCase();
  
    // Validate state code
    if (!stateCodes.includes(stateCode)) {
      return Promise.reject(new Error('Invalid state code in CIN.'));
    }
  
    // Validate company type
    if (!companyTypes.includes(companyType)) {
      return Promise.reject(new Error('Invalid company type in CIN.'));
    }
  
    return Promise.resolve();
  };
  