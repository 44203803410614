// src/utils/dinValidator.js

// DIN validation with basic format checks
export const validateDIN = (_, value) => {
    if (!value) {
      return Promise.reject('Please input the DIN!');
    }
  
    // Remove any spaces or special characters
    const cleanDIN = value.replace(/\D/g, '');
  
    // Check length - should be 8 digits (can be padded with leading zeros)
    if (cleanDIN.length !== 8) {
      return Promise.reject('DIN must be 8 digits (including leading zeros if applicable)');
    }
  
    // Basic format check (should only contain digits)
    if (!/^\d{8}$/.test(cleanDIN)) {
      return Promise.reject('DIN must contain only digits');
    }
  
    // For future implementation: API verification with MCA portal
    // This would be the proper way to verify a DIN's validity
    // const isValid = await verifyDINWithMCA(cleanDIN);
    // if (!isValid) {
    //   return Promise.reject('Invalid DIN. Please verify the number.');
    // }
  
    return Promise.resolve();
  };
  
  // Director types as per Companies Act
  export const DIRECTOR_TYPES = [
    'Managing Director',
    'Whole-Time Director',
    'Executive Director',
    'Non-Executive Director',
    'Independent Director',
    'Additional Director',
    'Alternate Director',
    'Nominee Director',
    'Small Shareholders Director'
  ];
  
  // KMP positions as per Companies Act
  export const KMP_POSITIONS = [
    'Chief Executive Officer (CEO)',
    'Chief Financial Officer (CFO)',
    'Company Secretary (CS)',
    'Whole-Time Director',
    'Manager'
  ];