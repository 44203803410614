import React, { useState, useContext } from 'react';
import { Button, Form, Input, Typography, Divider, Layout, message } from 'antd';
import { GoogleOutlined } from '@ant-design/icons';
import { useNavigate, Link } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import AppFooter from '../Layout/Footer';
import '../../styles/Auth.css';

const { Title, Text } = Typography;
const { Content } = Layout;

const LumioLogo = () => (
  <svg width="40" height="40" viewBox="0 0 1080 1080" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="1080" height="1080" rx="200" fill="#1A1A1A"/>
    <ellipse cx="540.501" cy="539.937" rx="58.5667" ry="58.5667" fill="#DDDDDD"/>
    <ellipse cx="700.883" cy="451.636" rx="58.5667" ry="58.5667" fill="#DDDDDD"/>
    <ellipse cx="380.117" cy="451.636" rx="58.5667" ry="58.5667" fill="#DDDDDD"/>
    <ellipse cx="379.217" cy="266.925" rx="36.0409" ry="36.0409" fill="#DDDDDD"/>
    <ellipse cx="226.041" cy="539.036" rx="36.0409" ry="36.0409" fill="#DDDDDD"/>
    <circle cx="226.942" cy="352.523" r="22.5253" fill="#DDDDDD"/>
    <circle cx="226.942" cy="725.549" r="22.5253" fill="#DDDDDD"/>
    <circle cx="854.057" cy="725.549" r="22.5253" fill="#DDDDDD"/>
    <circle cx="540.5" cy="900.348" r="22.5253" fill="#DDDDDD"/>
    <circle cx="540.5" cy="179.525" r="22.5253" fill="#DDDDDD"/>
    <circle cx="854.057" cy="352.523" r="22.5253" fill="#DDDDDD"/>
    <ellipse cx="854.959" cy="539.036" rx="36.0409" ry="36.0409" fill="#DDDDDD"/>
    <ellipse cx="379.217" cy="816.553" rx="36.0409" ry="36.0409" fill="#DDDDDD"/>
    <ellipse cx="701.784" cy="816.553" rx="36.0409" ry="36.0409" fill="#DDDDDD"/>
    <ellipse cx="701.784" cy="266.925" rx="36.0409" ry="36.0409" fill="#DDDDDD"/>
    <ellipse cx="700.883" cy="631.842" rx="58.5667" ry="58.5667" fill="#DDDDDD"/>
    <ellipse cx="380.117" cy="631.842" rx="58.5667" ry="58.5667" fill="#DDDDDD"/>
    <ellipse cx="540.501" cy="352.523" rx="58.5667" ry="58.5667" fill="#DDDDDD"/>
    <ellipse cx="540.501" cy="745.372" rx="58.5667" ry="58.5667" fill="#DDDDDD"/>
  </svg>
);

const Login = () => {
  const { login, googleLogin } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    setLoading(true);
    try {
      await login(values.email, values.password);
      navigate('/dashboard');
    } catch (error) {
      message.error(error.message || 'Failed to login. Please try again.');
      console.error('Login error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      await googleLogin();
      navigate('/dashboard');
    } catch (error) {
      message.error('Google login failed. Please try again.');
      console.error('Google login error:', error);
    }
  };

  return (
    <Layout>
      <nav className="landing-nav">
        <div className="nav-logo">
          <Link to="/">
            <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
              <LumioLogo />
              <Title level={3} style={{ margin: 0 }}>lumioCapital</Title>
            </div>
          </Link>
        </div>
        <div className="nav-buttons">
          <Button size="large" type="link" onClick={() => navigate('/login')}>Login</Button>
          <Button size="large" type="primary" onClick={() => navigate('/register')}>Get Started</Button>
        </div>
      </nav>

      <Content>
        <div className="auth-container">
          <div className="auth-background">
            <div className="auth-circle auth-circle-1" />
            <div className="auth-circle auth-circle-2" />
          </div>
          
          <div className="auth-card">
            <div className="auth-header">
              <Title level={2}>Welcome Back</Title>
              <Text type="secondary">Sign in to your account to continue</Text>
            </div>

            <Form
              form={form}
              name="login"
              onFinish={onFinish}
              layout="vertical"
              className="auth-form"
              autoComplete="off"
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: 'Please enter your email' },
                  { type: 'email', message: 'Please enter a valid email' }
                ]}
              >
                <Input size="large" placeholder="Enter your email" />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: 'Please enter your password' }]}
              >
                <Input.Password size="large" placeholder="Enter your password" />
              </Form.Item>

              <Form.Item>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '16px' }}>
                  <Link to="/forgot-password">Forgot password?</Link>
                </div>
                <Button 
                  type="primary"
                  htmlType="submit"
                  block
                  size="large"
                  loading={loading}
                >
                  Sign In
                </Button>
              </Form.Item>

              <Divider>or continue with</Divider>

              <Button
                className="google-button"
                icon={<GoogleOutlined />}
                block
                size="large"
                onClick={handleGoogleLogin}
              >
                Sign in with Google
              </Button>

              <div className="auth-footer">
                <Text type="secondary">
                  Don't have an account? <Link to="/register">Create one now</Link>
                </Text>
              </div>
            </Form>
          </div>

          <div className="auth-features">
            <Title level={3}>Why Choose lumioCapital</Title>
            <ul>
              <li>Easy share transfer management</li>
              <li>Automated compliance tracking</li>
              <li>Secure data storage</li>
              <li>Real-time shareholding insights</li>
            </ul>
          </div>
        </div>
      </Content>
      <AppFooter />
    </Layout>
  );
};

export default Login;