import React from 'react';
import { Layout, Row, Col, Space, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { 
  MailOutlined, 
  WhatsAppOutlined,
  LinkedinOutlined,
  XOutlined
} from '@ant-design/icons';

const { Footer } = Layout;
const { Text, Title } = Typography;

const LumioLogo = () => (
  <svg 
    width="48" 
    height="48" 
    viewBox="0 0 1080 1080" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    style={{
      minWidth: '48px',
      minHeight: '48px'
    }}
  >
    <rect width="1080" height="1080" rx="200" fill="#1A1A1A"/>
    <ellipse cx="540.501" cy="539.937" rx="58.5667" ry="58.5667" fill="#DDDDDD"/>
    <ellipse cx="700.883" cy="451.636" rx="58.5667" ry="58.5667" fill="#DDDDDD"/>
    <ellipse cx="380.117" cy="451.636" rx="58.5667" ry="58.5667" fill="#DDDDDD"/>
    <ellipse cx="379.217" cy="266.925" rx="36.0409" ry="36.0409" fill="#DDDDDD"/>
    <ellipse cx="226.041" cy="539.036" rx="36.0409" ry="36.0409" fill="#DDDDDD"/>
    <circle cx="226.942" cy="352.523" r="22.5253" fill="#DDDDDD"/>
    <circle cx="226.942" cy="725.549" r="22.5253" fill="#DDDDDD"/>
    <circle cx="854.057" cy="725.549" r="22.5253" fill="#DDDDDD"/>
    <circle cx="540.5" cy="900.348" r="22.5253" fill="#DDDDDD"/>
    <circle cx="540.5" cy="179.525" r="22.5253" fill="#DDDDDD"/>
    <circle cx="854.057" cy="352.523" r="22.5253" fill="#DDDDDD"/>
    <ellipse cx="854.959" cy="539.036" rx="36.0409" ry="36.0409" fill="#DDDDDD"/>
    <ellipse cx="379.217" cy="816.553" rx="36.0409" ry="36.0409" fill="#DDDDDD"/>
    <ellipse cx="701.784" cy="816.553" rx="36.0409" ry="36.0409" fill="#DDDDDD"/>
    <ellipse cx="701.784" cy="266.925" rx="36.0409" ry="36.0409" fill="#DDDDDD"/>
    <ellipse cx="700.883" cy="631.842" rx="58.5667" ry="58.5667" fill="#DDDDDD"/>
    <ellipse cx="380.117" cy="631.842" rx="58.5667" ry="58.5667" fill="#DDDDDD"/>
    <ellipse cx="540.501" cy="352.523" rx="58.5667" ry="58.5667" fill="#DDDDDD"/>
    <ellipse cx="540.501" cy="745.372" rx="58.5667" ry="58.5667" fill="#DDDDDD"/>
  </svg>
);

const AppFooter = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Footer style={{ 
      backgroundColor: '#fff',
      padding: '48px 24px 24px',
      borderTop: '1px solid #f0f0f0'
    }}>
      <div style={{ maxWidth: '1200px', margin: '0 auto', width: '100%' }}>
        <Row gutter={[32, 32]} justify="space-between">
          {/* Logo and Company Info */}
          <Col xs={24} md={12} style={{ paddingLeft: '48px' }}>
            <Space direction="vertical" size={16} style={{ width: '100%' }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                <LumioLogo />
                <a 
                  href="https://lumiotech.in" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  style={{ 
                    color: '#1A1A1A',
                    textDecoration: 'none'
                  }}
                >
                  <Title 
                    level={4} 
                    style={{ 
                      margin: 0,
                      letterSpacing: '-0.01em'
                    }}
                  >
                    Lumiotech
                  </Title>
                </a>
              </div>
              <Text style={{ color: '#6b7280' }}>
                Powering tomorrow's progress through intelligent enterprise solutions and mission-critical government platforms worldwide
              </Text>
              <Space direction="vertical" size={12}>
                <Text strong style={{ color: '#1A1A1A' }}>
                  Contact Us
                </Text>
                <Space direction="vertical" size={8}>
                  <a 
                    href="mailto:shares@lumiotech.in"
                    style={{ 
                      color: '#6b7280',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px'
                    }}
                  >
                    <MailOutlined /> info@lumiotech.in
                  </a>
                  {/* <a 
                    href="https://wa.me/918920475200"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ 
                      color: '#6b7280',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px'
                    }}
                  >
                    <WhatsAppOutlined /> +91 89204 75200
                  </a> */}
                </Space>
                <Space direction="vertical" size={8}>
                  <Text strong style={{ color: '#1A1A1A', marginTop: '12px' }}>
                    Follow Us
                  </Text>
                  <Space size={16}>
                    <a 
                      href="https://x.com/lumiotech"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: '#6b7280' }}
                    >
                      <XOutlined style={{ fontSize: '20px' }} />
                    </a>
                    <a 
                      href="https://www.linkedin.com/company/lumiotech-in"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: '#6b7280' }}
                    >
                      <LinkedinOutlined style={{ fontSize: '20px' }} />
                    </a>
                  </Space>
                </Space>
              </Space>
            </Space>
          </Col>

          {/* Legal Links */}
          <Col xs={24} md={6}>
            <Space direction="vertical" size={16} style={{ width: '100%' }}>
              <Title level={5} style={{ color: '#1A1A1A', margin: 0 }}>
                Legal
              </Title>
              <Space direction="vertical" size={12}>
                <Link to="/privacy-policy" style={{ color: '#6b7280' }}>
                  Privacy Policy
                </Link>
                <Link to="/terms-of-service" style={{ color: '#6b7280' }}>
                  Terms of Service
                </Link>
                <Link to="/data-protection" style={{ color: '#6b7280' }}>
                  Data Protection
                </Link>
                <Link to="/compliance" style={{ color: '#6b7280' }}>
                  Compliance
                </Link>
                <Link to="/security" style={{ color: '#6b7280' }}>
                  Security
                </Link>
              </Space>
            </Space>
          </Col>
        </Row>

        {/* Alpha Testing Note */}
        <Row style={{ marginTop: 32 }}>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Text type="secondary">
              lumioCapital is currently in Alpha Testing. The platform will improve over-time.
              Report bugs to <a href="mailto:info@lumiotech.in">info@lumiotech.in</a>
            </Text>
          </Col>
        </Row>

        {/* Copyright */}
        <Row style={{ marginTop: 48, paddingTop: 24, borderTop: '1px solid #f0f0f0' }}>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Text style={{ color: '#6b7280' }}>
              © {currentYear} Lumiotech Private Limited. All rights reserved.
            </Text>
          </Col>
        </Row>
      </div>
    </Footer>
  );
};

export default AppFooter;