// src/pages/legal/DataProtection.js

import React from 'react';
import { Typography, Breadcrumb, Layout } from 'antd';
import { Link } from 'react-router-dom';
import AppFooter from '../../components/Layout/Footer';
import LandingNav from '../../components/Layout/LandingNav';
import '../../styles/Legal.css';

const { Title, Paragraph, Text } = Typography;
const { Content } = Layout;

const DataProtection = () => {
  return (
    <Layout>
      <LandingNav title="lumioCapital" />
      
      <Content>
        <div className="legal-container">
          <div className="legal-content">
            <div className="legal-navigation">
              <Breadcrumb>
                <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item>Data Protection</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="legal-header">
              <Title level={1}>Data Protection Policy</Title>
              <Text className="last-updated">Last Updated: December 5, 2024</Text>
            </div>

            <div className="legal-section">
              <section className="legal-introduction">
                <Paragraph>
                  This Data Protection Policy ("Policy") outlines how Lumiotech Private Limited ("Company") protects data in compliance with:
                </Paragraph>
                <ul>
                  <li>Information Technology Act, 2000</li>
                  <li>Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011</li>
                  <li>Proposed Personal Data Protection Bill (as applicable)</li>
                  <li>Other applicable data protection laws and regulations</li>
                </ul>
              </section>

              <section>
                <Title level={2}>1. Data Classification</Title>
                <Title level={3}>1.1 Categories of Data</Title>
                <Paragraph>
                  We classify data into the following categories:
                </Paragraph>
                <ul>
                  <li><Text strong>Personal Data:</Text> Information that can identify an individual</li>
                  <li><Text strong>Sensitive Personal Data:</Text> Passwords, financial information, etc.</li>
                  <li><Text strong>Corporate Data:</Text> Company information and business records</li>
                  <li><Text strong>Public Data:</Text> Information available in public domain</li>
                </ul>

                <Title level={3}>1.2 Special Categories</Title>
                <Paragraph>
                  We handle the following special categories of data:
                </Paragraph>
                <ul>
                  <li>Share ownership records</li>
                  <li>Financial transactions</li>
                  <li>Corporate governance documents</li>
                  <li>Regulatory filings</li>
                </ul>
              </section>

              <section>
                <Title level={2}>2. Data Collection and Processing</Title>
                <Title level={3}>2.1 Lawful Basis</Title>
                <Paragraph>
                  We collect and process data based on:
                </Paragraph>
                <ul>
                  <li>Explicit user consent</li>
                  <li>Contractual obligations</li>
                  <li>Legal requirements</li>
                  <li>Legitimate business interests</li>
                </ul>

                <Title level={3}>2.2 Purpose Limitation</Title>
                <Paragraph>
                  Data is collected and processed only for:
                </Paragraph>
                <ul>
                  <li>Providing platform services</li>
                  <li>Regulatory compliance</li>
                  <li>Service improvement</li>
                  <li>Security purposes</li>
                </ul>
              </section>

              <section>
                <Title level={2}>3. Data Storage and Security</Title>
                <Title level={3}>3.1 Storage Location</Title>
                <Paragraph>
                  All data is stored on servers located in India, in compliance with data localization requirements.
                </Paragraph>

                <Title level={3}>3.2 Security Measures</Title>
                <Paragraph>
                  We implement the following security measures:
                </Paragraph>
                <ul>
                  <li>End-to-end encryption</li>
                  <li>Access control and authentication</li>
                  <li>Regular security audits</li>
                  <li>Intrusion detection systems</li>
                  <li>Data backup and recovery</li>
                  <li>Employee security training</li>
                </ul>
              </section>

              <section>
                <Title level={2}>4. Data Retention</Title>
                <Paragraph>
                  4.1 We retain data for the following periods:
                </Paragraph>
                <ul>
                  <li>Active account data: Throughout the service period</li>
                  <li>Transaction records: 8 years (as per Companies Act)</li>
                  <li>Audit logs: 5 years</li>
                  <li>Communication records: 3 years</li>
                </ul>

                <Paragraph>
                  4.2 Data may be retained longer if required by law or for legitimate business purposes.
                </Paragraph>
              </section>

              <section>
                <Title level={2}>5. Data Access and Rights</Title>
                <Paragraph>
                  Users have the following rights regarding their data:
                </Paragraph>
                <ul>
                  <li>Right to access</li>
                  <li>Right to correction</li>
                  <li>Right to data portability</li>
                  <li>Right to erasure (subject to legal requirements)</li>
                  <li>Right to withdraw consent</li>
                </ul>
              </section>

              <section>
                <Title level={2}>6. Data Sharing and Transfers</Title>
                <Title level={3}>6.1 Internal Sharing</Title>
                <Paragraph>
                  Data is shared internally on a need-to-know basis with:
                </Paragraph>
                <ul>
                  <li>Authorized employees</li>
                  <li>System administrators</li>
                  <li>Security personnel</li>
                </ul>

                <Title level={3}>6.2 External Sharing</Title>
                <Paragraph>
                  Data may be shared with:
                </Paragraph>
                <ul>
                  <li>Regulatory authorities</li>
                  <li>Service providers</li>
                  <li>Legal advisors</li>
                  <li>Auditors</li>
                </ul>
              </section>

              <section>
                <Title level={2}>7. Data Breach Protocol</Title>
                <Paragraph>
                  In the event of a data breach:
                </Paragraph>
                <ul>
                  <li>Immediate internal notification</li>
                  <li>Assessment of breach impact</li>
                  <li>User notification within 72 hours</li>
                  <li>Regulatory reporting as required</li>
                  <li>Implementation of remedial measures</li>
                </ul>
              </section>

              <section>
                <Title level={2}>8. Compliance and Accountability</Title>
                <Paragraph>
                  We maintain compliance through:
                </Paragraph>
                <ul>
                  <li>Regular audits and assessments</li>
                  <li>Employee training programs</li>
                  <li>Documentation of procedures</li>
                  <li>Incident response planning</li>
                  <li>Privacy impact assessments</li>
                </ul>
              </section>

              <section>
                <Title level={2}>9. User Responsibilities</Title>
                <Paragraph>
                  Users are responsible for:
                </Paragraph>
                <ul>
                  <li>Maintaining confidentiality of credentials</li>
                  <li>Ensuring accuracy of provided data</li>
                  <li>Reporting unauthorized access</li>
                  <li>Complying with security policies</li>
                </ul>
              </section>

              <section>
                <Title level={2}>10. Contact Information</Title>
                <div className="legal-contact">
                  <Title level={3}>Data Protection Queries</Title>
                  <Paragraph>
                    For any questions regarding data protection:<br />
                    Data Protection Officer<br />
                    Email: dpo@lumiotech.in<br />
                  </Paragraph>
                </div>
              </section>
            </div>
          </div>
        </div>
      </Content>

      <AppFooter />
    </Layout>
  );
};

export default DataProtection;