// src/pages/legal/Compliance.js

import React from 'react';
import { Typography, Breadcrumb, Layout } from 'antd';
import { Link } from 'react-router-dom';
import AppFooter from '../../components/Layout/Footer';
import LandingNav from '../../components/Layout/LandingNav';
import '../../styles/Legal.css';

const { Title, Paragraph, Text } = Typography;
const { Content } = Layout;

const Compliance = () => {
  return (
    <Layout className="legal-page-layout">
      <LandingNav title="lumioCapital" />
      
      <Content>
        <div className="legal-container">
          <div className="legal-content">
            <div className="legal-navigation">
              <Breadcrumb>
                <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item>Compliance</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="legal-header">
              <Title level={1}>Compliance Policy</Title>
              <Text className="last-updated">Last Updated: December 5, 2024</Text>
            </div>

            <div className="legal-section">
              <section className="legal-introduction">
                <Paragraph>
                  This Compliance Policy outlines how Lumiotech Private Limited ("Company") ensures adherence to various legal and regulatory requirements in the operation of the lumioCapital platform.
                </Paragraph>
              </section>

              <section>
                <Title level={2}>1. Regulatory Framework</Title>
                <Paragraph>
                  Our platform operates in compliance with:
                </Paragraph>
                <ul>
                  <li>Companies Act, 2013</li>
                  <li>Information Technology Act, 2000</li>
                  <li>Securities Contracts (Regulation) Act, 1956</li>
                  <li>SEBI Guidelines (as applicable)</li>
                  <li>Reserve Bank of India regulations</li>
                </ul>
              </section>

              <section>
                <Title level={2}>2. Share Management Compliance</Title>
                
                <Title level={3}>2.1 Share Transfers</Title>
                <Paragraph>
                  All share transfers processed through our platform must comply with:
                </Paragraph>
                <ul>
                  <li>Section 56 of the Companies Act, 2013</li>
                  <li>Company's Articles of Association</li>
                  <li>Shareholders' Agreement</li>
                  <li>Board-approved transfer procedures</li>
                </ul>

                <Title level={3}>2.2 Record Keeping</Title>
                <Paragraph>
                  We maintain records as required by:
                </Paragraph>
                <ul>
                  <li>Section 88 (Register of Members)</li>
                  <li>Section 92 (Annual Returns)</li>
                  <li>Section 170 (Register of Directors and KMP)</li>
                  <li>Other applicable provisions</li>
                </ul>
              </section>

              <section>
                <Title level={2}>3. Digital Compliance</Title>
                <Paragraph>
                  Our digital infrastructure complies with:
                </Paragraph>
                <ul>
                  <li>IT Act Security Guidelines</li>
                  <li>Digital Signature requirements</li>
                  <li>Electronic Records maintenance standards</li>
                  <li>Cyber Security Guidelines</li>
                </ul>
              </section>

              <section>
                <Title level={2}>4. User Obligations</Title>
                <Paragraph>
                  Platform users are required to:
                </Paragraph>
                <ul>
                  <li>Maintain accuracy of information</li>
                  <li>Follow prescribed procedures</li>
                  <li>Obtain necessary approvals</li>
                  <li>Maintain confidentiality</li>
                  <li>Report compliance issues</li>
                </ul>
              </section>

              <section>
                <Title level={2}>5. Corporate Governance</Title>
                <Title level={3}>5.1 Board Oversight</Title>
                <Paragraph>
                  The platform facilitates:
                </Paragraph>
                <ul>
                  <li>Board resolution management</li>
                  <li>Director identification compliance</li>
                  <li>Corporate action documentation</li>
                  <li>Regulatory filing tracking</li>
                </ul>

                <Title level={3}>5.2 Shareholder Rights</Title>
                <Paragraph>
                  We ensure protection of:
                </Paragraph>
                <ul>
                  <li>Voting rights</li>
                  <li>Pre-emptive rights</li>
                  <li>Information rights</li>
                  <li>Transfer rights</li>
                </ul>
              </section>

              <section>
                <Title level={2}>6. Audit and Monitoring</Title>
                <Paragraph>
                  Our compliance monitoring includes:
                </Paragraph>
                <ul>
                  <li>Regular internal audits</li>
                  <li>External compliance reviews</li>
                  <li>System security assessments</li>
                  <li>User activity monitoring</li>
                  <li>Regulatory update tracking</li>
                </ul>
              </section>

              <section>
                <Title level={2}>7. Reporting and Disclosure</Title>
                <Paragraph>
                  We maintain transparency through:
                </Paragraph>
                <ul>
                  <li>Regular compliance reports</li>
                  <li>Statutory filings</li>
                  <li>User notifications</li>
                  <li>Regulatory disclosures</li>
                </ul>
              </section>

              <section>
                <Title level={2}>8. Risk Management</Title>
                <Paragraph>
                  Our risk management framework covers:
                </Paragraph>
                <ul>
                  <li>Operational risks</li>
                  <li>Legal and regulatory risks</li>
                  <li>Technology risks</li>
                  <li>Data security risks</li>
                </ul>
              </section>

              <section>
                <Title level={2}>9. Non-Compliance</Title>
                <Paragraph>
                  Consequences of non-compliance may include:
                </Paragraph>
                <ul>
                  <li>Service suspension</li>
                  <li>Account termination</li>
                  <li>Legal action</li>
                  <li>Regulatory reporting</li>
                </ul>
              </section>

              <section>
                <Title level={2}>10. Updates and Changes</Title>
                <Paragraph>
                  We reserve the right to update this policy to:
                </Paragraph>
                <ul>
                  <li>Reflect regulatory changes</li>
                  <li>Improve compliance measures</li>
                  <li>Address new requirements</li>
                  <li>Enhance user protection</li>
                </ul>
              </section>

              <section>
                <Title level={2}>11. Contact Information</Title>
                <div className="legal-contact">
                  <Title level={3}>Compliance Queries</Title>
                  <Paragraph>
                    For compliance-related questions or reporting:<br />
                    Compliance Officer<br />
                    Email: legal@lumiotech.in<br />
                  </Paragraph>
                </div>
              </section>
            </div>
          </div>
        </div>
      </Content>

      <AppFooter />
    </Layout>
  );
};

export default Compliance;