import React, { useContext } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Layout, ConfigProvider, Spin } from 'antd';
import { AuthProvider, AuthContext } from './contexts/AuthContext';
import PrivateRoute from './components/Common/PrivateRoute';


// Layout Components
import Header from './components/Layout/Header';
import Footer from './components/Layout/Footer';

// Core Pages
import Dashboard from './pages/Dashboard';
import ShareholdersPage from './pages/ShareholdersPage';
import TransactionsPage from './pages/TransactionsPage';
import CompanyProfile from './pages/CompanyProfile';
import CompleteProfile from './pages/CompleteProfile';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import ForgotPassword from './components/Auth/ForgotPassword';
import NotFound from './pages/NotFound';
import LandingPage from './pages/LandingPage';

// Legal Pages
import PrivacyPolicy from './pages/legal/PrivacyPolicy';
import TermsOfService from './pages/legal/TermsOfService';
import DataProtection from './pages/legal/DataProtection';
import Compliance from './pages/legal/Compliance';
import Security from './pages/legal/Security'

const { Content } = Layout;

function App() {
  return (
    <ConfigProvider>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </ConfigProvider>
  );
}

const AppContent = () => {
  const { currentUser, profileComplete, loading, authLoading } = useContext(AuthContext);
  const location = useLocation();
  
  const publicRoutes = ['/', '/login', '/register', '/forgot-password'];
  const legalRoutes = [
    '/privacy-policy',
    '/terms-of-service',
    '/data-protection',
    '/compliance',
    '/security'
  ];
  
  const isPublicRoute = publicRoutes.includes(location.pathname);
  const isLegalRoute = legalRoutes.includes(location.pathname);

  if (loading || authLoading) {
    return (
      <div style={{ 
        height: '100vh', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        background: '#f0f2f5'
      }}>
        <Spin size="large" />
      </div>
    );
  }

  // If user is authenticated and tries to access public routes except '/', redirect to dashboard
  if (currentUser && isPublicRoute && location.pathname !== '/') {
    return <Navigate to={profileComplete ? "/dashboard" : "/complete-profile"} replace />;
  }

  // If it's a public or legal route, don't show the app layout
  if (isPublicRoute || isLegalRoute) {
    return <AppRoutes />;
  }

  // For all other routes, use the app layout
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header />
      <Content>
        <AppRoutes />
      </Content>
      <Footer />
    </Layout>
  );
};

const AppRoutes = () => {
  const { currentUser, profileComplete, loading } = useContext(AuthContext);

  // Don't render routes while still loading
  if (loading) return null;

  const legalRoutes = (
    <>
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-of-service" element={<TermsOfService />} />
      <Route path="/data-protection" element={<DataProtection />} />
      <Route path="/compliance" element={<Compliance />} />
      <Route path="/security" element={<Security />} />
    </>
  );

  if (currentUser) {
    if (profileComplete) {
      return (
        <Routes>
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
          <Route 
            path="/dashboard" 
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/shareholders" 
            element={
              <PrivateRoute>
                <ShareholdersPage />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/transactions" 
            element={
              <PrivateRoute>
                <TransactionsPage />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/company-profile" 
            element={
              <PrivateRoute>
                <CompanyProfile />
              </PrivateRoute>
            } 
          />
          {legalRoutes}
          <Route path="*" element={<NotFound />} />
        </Routes>
      );
    }

    // Profile not complete, show complete profile route
    return (
      <Routes>
        <Route path="/complete-profile" element={<CompleteProfile />} />
        {legalRoutes}
        <Route path="*" element={<Navigate to="/complete-profile" replace />} />
      </Routes>
    );
  }

  // Not authenticated, show public routes
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      {legalRoutes}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default App;