import React from 'react';
import { Typography, Card, Row, Col, Layout, Button } from 'antd';
import { 
  LockOutlined, 
  BarChartOutlined, 
  SafetyOutlined,
  CheckOutlined,
  BookOutlined,
  CloudOutlined,
  TeamOutlined,
  ArrowRightOutlined
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import LandingNav from '../components/Layout/LandingNav';
import ContactForm from '../components/Landing/ContactForm';
import AppFooter from '../components/Layout/Footer';
import '../styles/Landing.css';

const { Title, Text, Paragraph } = Typography;
const { Content } = Layout;

const HeroBackground = () => (
  <div className="hero-background">
    <div className="circle circle-1"></div>
    <div className="circle circle-2"></div>
    <div className="circle circle-3"></div>
    <div className="line line-1"></div>
    <div className="line line-2"></div>
  </div>
);

export default function LandingPage() {
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate('/register');
  };

  const features = [
    {
      icon: <BookOutlined className="feature-icon" />,
      title: "Digital Share Register",
      description: "Keep your share records organized and updated. Track ownership changes easily."
    },
    {
      icon: <TeamOutlined className="feature-icon" />,
      title: "Transfer Management",
      description: "Handle share transfers smoothly with built-in checks and documentation."
    },
    {
      icon: <BarChartOutlined className="feature-icon" />,
      title: "Clear Reports",
      description: "Get straightforward reports about shareholding patterns and changes."
    },
    {
      icon: <CloudOutlined className="feature-icon" />,
      title: "Record Keeping",
      description: "Store share certificates and transfer documents securely in one place."
    },
    {
      icon: <SafetyOutlined className="feature-icon" />,
      title: "Compliance Tools",
      description: "Stay aligned with company law requirements without the complexity."
    },
    {
      icon: <LockOutlined className="feature-icon" />,
      title: "Data Security",
      description: "Your company's data is protected with industry-standard security measures."
    }
  ];

  const pricingPlans = [
    {
      name: "Starter",
      price: "Free",
      period: "Forever",
      features: [
        "Up to 10 shareholders",
        "Basic share register",
        "Share transfer records",
        "Standard reports",
        "Email support",
        "Single user access"
      ],
      buttonText: "Start Free",
      buttonType: "default",
      route: "/register"
    },
    {
      name: "Professional",
      price: "₹1,499",
      period: "/month",
      features: [
        "Up to 100 shareholders",
        "Custom share certificates",
        "Detailed transfer history",
        "Shareholding pattern reports",
        "Multi-user access (up to 3)",
        "Transfer deed generation",
        "Priority email support",
        "Data export options"
      ],
      buttonText: "Get Started",
      buttonType: "primary",
      route: "/register"
    },
    {
      name: "Enterprise",
      price: "Custom",
      period: "",
      features: [
        "Unlimited shareholders",
        "Custom workflows",
        "Dedicated support",
        "Unlimited users",
        "API access",
        "Data migration assistance",
        "Custom compliance reports",
        "Audit logs"
      ],
      buttonText: "Contact Sales",
      buttonType: "default",
      route: "contact"
    }
  ];

  return (
    <Layout className="landing-page">
      <LandingNav title="lumioCapital" />

      <Content>
        <section id="home" className="hero-section">
          <HeroBackground />
          <div className="hero-content">
            <Title level={1}>
              Manage Your Company's Shares with Confidence
            </Title>
            <Paragraph className="hero-subtitle">
              A clear, reliable way to handle share transfers, maintain records, and stay compliant. Built for Indian companies.
            </Paragraph>
            <div className="hero-buttons">
              <Button size="large" type="primary" onClick={handleGetStarted}>
                Start Free Today <ArrowRightOutlined />
              </Button>
            </div>
          </div>
        </section>

        <section id="features" className="features-section">
          <div className="container">
            <Title level={2} className="section-title">
              Everything You Need
            </Title>
            <Row gutter={[48, 48]} className="features-grid">
              {features.map((feature, index) => (
                <Col xs={24} sm={12} lg={8} key={index}>
                  <Card className="feature-card" bordered={false}>
                    <div className="feature-icon-wrapper">{feature.icon}</div>
                    <Title level={4}>{feature.title}</Title>
                    <Text>{feature.description}</Text>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </section>

        <section className="video-section">
          <div className="container">
            <Title level={2} className="section-title">
              See How It Works
            </Title>
            <div className="video-container">
              <iframe 
                src="https://scribehow.com/embed/Walkthrough_of_lumioCapital_Platform__WInpLoCHQcKVkyItkuZzuQ?as=video" 
                width="100%" 
                height="640" 
                allowFullScreen 
                frameBorder="0"
                title="lumioCapital Platform Walkthrough"
              />
            </div>
          </div>
        </section>

        <section id="pricing" className="pricing-section">
          <div className="container">
            <Title level={2} className="section-title">Plans That Grow With You</Title>
            <Row gutter={[48, 48]} className="pricing-grid">
              {pricingPlans.map((plan, index) => (
                <Col xs={24} md={8} key={index}>
                  <Card className="pricing-card" bordered={false}>
                    <div className="pricing-content">
                      <div className="pricing-header">
                        <Title level={3}>{plan.name}</Title>
                        <div className="pricing-amount">
                          <span className="amount">{plan.price}</span>
                          <span className="period">{plan.period}</span>
                        </div>
                      </div>
                      <ul className="pricing-features">
                        {plan.features.map((feature, idx) => (
                          <li key={idx}>
                            <CheckOutlined /> {feature}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="pricing-footer">
                      <Button 
                        type={plan.buttonType}
                        size="large"
                        block
                        onClick={() => {
                          if (plan.route === 'contact') {
                            const contactSection = document.getElementById('contact');
                            contactSection?.scrollIntoView({ behavior: 'smooth' });
                          } else {
                            navigate(plan.route);
                          }
                        }}
                      >
                        {plan.buttonText}
                      </Button>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </section>

        <section id="contact" className="contact-section">
          <div className="container">
            <Row gutter={[48, 48]} align="middle" justify="center">
              <Col xs={24} lg={12}>
                <div className="contact-content">
                  <Title level={2}>Get in Touch</Title>
                  <Paragraph>
                    Interested in our enterprise plan? Fill out the form and our team will get back to you within 24 hours.
                  </Paragraph>
                  <div className="contact-info">
                    <Title level={4}>Why Choose Enterprise?</Title>
                    <ul className="enterprise-features">
                      <li><CheckOutlined /> Custom implementation support</li>
                      <li><CheckOutlined /> Dedicated account manager</li>
                      <li><CheckOutlined /> Priority 24/7 support</li>
                      <li><CheckOutlined /> Custom integrations</li>
                      <li><CheckOutlined /> Enhanced security features</li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col xs={24} lg={12}>
                <div className="contact-form-wrapper">
                  <ContactForm />
                </div>
              </Col>
            </Row>
          </div>
        </section>

        <section className="cta-section">
          <div className="container">
            <Title level={2}>Begin Your Digital Share Management Journey</Title>
            <Paragraph>
              Join companies across India who trust us with their share management
            </Paragraph>
            <Button size="large" type="primary" onClick={handleGetStarted}>
              Start Free Today <ArrowRightOutlined />
            </Button>
          </div>
        </section>
      </Content>

      <AppFooter />
    </Layout>
  );
}