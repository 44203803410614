import React, { useState, useContext } from 'react';
import { Button, Form, Input, Typography, Card, message } from 'antd';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import '../../styles/Auth.css';

const { Title, Text } = Typography;

const LumioLogo = () => (
  <svg width="40" height="40" viewBox="0 0 1080 1080" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="1080" height="1080" rx="200" fill="#1A1A1A"/>
    <ellipse cx="540.501" cy="539.937" rx="58.5667" ry="58.5667" fill="#DDDDDD"/>
    <ellipse cx="700.883" cy="451.636" rx="58.5667" ry="58.5667" fill="#DDDDDD"/>
    <ellipse cx="380.117" cy="451.636" rx="58.5667" ry="58.5667" fill="#DDDDDD"/>
    <ellipse cx="379.217" cy="266.925" rx="36.0409" ry="36.0409" fill="#DDDDDD"/>
    <ellipse cx="226.041" cy="539.036" rx="36.0409" ry="36.0409" fill="#DDDDDD"/>
    <circle cx="226.942" cy="352.523" r="22.5253" fill="#DDDDDD"/>
    <circle cx="226.942" cy="725.549" r="22.5253" fill="#DDDDDD"/>
    <circle cx="854.057" cy="725.549" r="22.5253" fill="#DDDDDD"/>
    <circle cx="540.5" cy="900.348" r="22.5253" fill="#DDDDDD"/>
    <circle cx="540.5" cy="179.525" r="22.5253" fill="#DDDDDD"/>
    <circle cx="854.057" cy="352.523" r="22.5253" fill="#DDDDDD"/>
    <ellipse cx="854.959" cy="539.036" rx="36.0409" ry="36.0409" fill="#DDDDDD"/>
    <ellipse cx="379.217" cy="816.553" rx="36.0409" ry="36.0409" fill="#DDDDDD"/>
    <ellipse cx="701.784" cy="816.553" rx="36.0409" ry="36.0409" fill="#DDDDDD"/>
    <ellipse cx="701.784" cy="266.925" rx="36.0409" ry="36.0409" fill="#DDDDDD"/>
    <ellipse cx="700.883" cy="631.842" rx="58.5667" ry="58.5667" fill="#DDDDDD"/>
    <ellipse cx="380.117" cy="631.842" rx="58.5667" ry="58.5667" fill="#DDDDDD"/>
    <ellipse cx="540.501" cy="352.523" rx="58.5667" ry="58.5667" fill="#DDDDDD"/>
    <ellipse cx="540.501" cy="745.372" rx="58.5667" ry="58.5667" fill="#DDDDDD"/>
  </svg>
);

const ForgotPassword = () => {
  const { resetPassword } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    setLoading(true);
    try {
      await resetPassword(values.email);
      message.success('Password reset email sent! Please check your inbox.');
      form.resetFields();
    } catch (error) {
      message.error(error.message || 'Failed to send reset email. Please try again.');
      console.error('Reset password error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="auth-container">
      <div className="auth-background">
        <div className="auth-circle auth-circle-1" />
        <div className="auth-circle auth-circle-2" />
      </div>

      <Card className="auth-card">
        <div className="auth-header">
          <div style={{ marginBottom: '24px', display: 'flex', justifyContent: 'center' }}>
            <LumioLogo />
          </div>
          <Title level={2}>Reset Password</Title>
          <Text type="secondary">Enter your email to receive reset instructions</Text>
        </div>

        <Form
          form={form}
          name="forgot_password"
          onFinish={onFinish}
          layout="vertical"
          className="auth-form"
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: 'Please enter your email' },
              { type: 'email', message: 'Please enter a valid email' }
            ]}
          >
            <Input size="large" placeholder="Enter your email" />
          </Form.Item>

          <Form.Item>
            <Button 
              type="primary" 
              htmlType="submit" 
              block 
              size="large"
              loading={loading}
            >
              Send Reset Link
            </Button>
          </Form.Item>

          <div className="auth-footer">
            <Text type="secondary">
              Remember your password? <Link to="/login">Sign in</Link>
            </Text>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default ForgotPassword;