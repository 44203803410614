import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Spin, Avatar, Card, Tooltip } from 'antd';
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  Legend,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  BarChart,
  Bar
} from 'recharts';
import { AuthContext } from '../contexts/AuthContext';
import { db } from '../firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { 
  ArrowUpOutlined,
  ArrowDownOutlined,
  InfoCircleOutlined,
  DollarCircleOutlined,
  UsergroupAddOutlined,
  StockOutlined,
  PieChartOutlined
} from '@ant-design/icons';
import '../styles/Dashboard.css';

const Dashboard = () => {
  const { companyData, currentUser } = useContext(AuthContext);
  const [shareholdersData, setShareholdersData] = useState([]);
  const [historicalData, setHistoricalData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchShareholders = async () => {
      if (!currentUser) return;
      try {
        const shareholdersRef = collection(db, 'shareholders');
        const q = query(shareholdersRef, where("companyId", "==", currentUser.uid));
        const querySnapshot = await getDocs(q);
        const data = querySnapshot.docs.map((doc) => doc.data());
        setShareholdersData(data);
      } catch (error) {
        console.error('Error fetching shareholders data:', error);
      }
      setLoading(false);
    };

    fetchShareholders();
  }, [currentUser]);

  useEffect(() => {
    const fetchTransfers = async () => {
      if (!currentUser) return;

      const transfersRef = collection(db, 'transfers');
      const q = query(transfersRef, where("companyId", "==", currentUser.uid));
      const querySnapshot = await getDocs(q);
      const transfers = querySnapshot.docs.map((doc) => doc.data());

      const monthlyData = {};
      transfers.forEach((transfer) => {
        if (!transfer.date || !transfer.sharesAllotted) return;
        const date = transfer.date.toDate();
        const month = date.toLocaleString('default', { month: 'short' });
        if (!monthlyData[month]) {
          monthlyData[month] = 0;
        }
        monthlyData[month] += Number(transfer.sharesAllotted);
      });

      const monthOrder = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const historicalArray = monthOrder
        .filter((m) => monthlyData[m])
        .map((m) => ({ month: m, sharesAllotted: monthlyData[m] }));

      setHistoricalData(historicalArray);
    };

    fetchTransfers();
  }, [currentUser]);

  if (loading || !companyData) {
    return (
      <div className="loading-container">
        <Spin size="large" tip="Loading your dashboard..." />
      </div>
    );
  }

  const totalIssuedShares = Number(companyData.paidUpCapital) / Number(companyData.nominalShareValue);
  const totalAllottedShares = shareholdersData.reduce(
    (acc, sh) => acc + Number(sh.totalShares || 0),
    0
  );

  const activeShareholders = shareholdersData.filter((sh) => sh.status !== 'Inactive');
  const activeShareholdersCount = activeShareholders.length;

  const unallottedShares = totalIssuedShares - totalAllottedShares;

  // Prepare data for pie chart
  const chartData = activeShareholders.map((sh) => ({
    name: sh.names ? sh.names.join(' / ') : 'Unnamed Shareholder',
    folioNumber: sh.folioNumber || 'N/A',
    totalShares: Number(sh.totalShares || 0),
    value: Number(sh.totalShares || 0),
  }));

  if (unallottedShares > 0) {
    chartData.push({
      name: 'Unallotted Shares',
      folioNumber: 'N/A',
      totalShares: unallottedShares,
      value: unallottedShares,
    });
  }

  chartData.sort((a, b) => b.totalShares - a.totalShares);

  // Additional Insights:
  const allottedOnly = chartData.filter((d) => d.name !== 'Unallotted Shares');
  const largestHolder = allottedOnly.length > 0 ? allottedOnly[0] : null;
  const largestHolderPercentage = largestHolder
    ? ((largestHolder.totalShares / totalIssuedShares) * 100).toFixed(2)
    : null;

  const avgSharesPerActive = activeShareholdersCount > 0
    ? Math.round(totalAllottedShares / activeShareholdersCount)
    : 0;

  const smallHoldersCount = allottedOnly.filter(
    (d) => ((d.totalShares / totalIssuedShares) * 100) < 1
  ).length;

  const COLORS = [
    '#2193b0', '#6dd5ed', '#4286f4', '#373B44',
    '#ff6b6b', '#4caf50', '#ff9800', '#f44336',
    '#9C27B0', '#3F51B5', '#009688', '#795548'
  ];

  const StatCard = ({ title, value, prefix, suffix, info, trend, icon }) => (
    <Card className="stat-card">
      <div className="stat-card__header">
        <span className="stat-card__title">
          {icon}
          {title}
          {info && (
            <Tooltip title={info}>
              <InfoCircleOutlined className="info-icon" />
            </Tooltip>
          )}
        </span>
        {trend && (
          <span className={`trend-indicator ${trend > 0 ? 'positive' : 'negative'}`}>
            {trend > 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
            {Math.abs(trend)}%
          </span>
        )}
      </div>
      <div className="stat-card__value">
        {prefix}{value.toLocaleString('en-IN')}{suffix}
      </div>
    </Card>
  );

  const CompanyInfoCard = () => (
    <Card className="company-info-card">
      <div className="company-info-header">
        {companyData.logoURL ? (
          <Avatar
            size={72}
            src={companyData.logoURL}
            className="company-avatar"
          />
        ) : (
          <Avatar
            size={72}
            className="company-avatar"
            style={{
              backgroundColor: '#1A1A1A',
              fontSize: '32px',
              fontWeight: '600',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {companyData?.companyName?.charAt(0)?.toUpperCase()}
          </Avatar>
        )}
        <div className="company-info-title">
          <h2>{companyData.companyName}</h2>
          <p className="company-cin">{companyData.cin}</p>
        </div>
      </div>
      <div className="company-info-grid">
        <div className="info-item">
          <span className="info-label">Authorized Capital</span>
          <span className="info-value">₹{Number(companyData.authorizedCapital).toLocaleString('en-IN')}</span>
        </div>
        <div className="info-item">
          <span className="info-label">Paid-up Capital</span>
          <span className="info-value">₹{Number(companyData.paidUpCapital).toLocaleString('en-IN')}</span>
        </div>
        <div className="info-item">
          <span className="info-label">Nominal Share Value</span>
          <span className="info-value">₹{Number(companyData.nominalShareValue).toLocaleString('en-IN')}</span>
        </div>
        <div className="info-item">
          <span className="info-label">Total Shareholders</span>
          <span className="info-value">{shareholdersData.length}</span>
        </div>
      </div>
    </Card>
  );

  const CustomTooltipContent = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      const percentage = ((data.totalShares / totalIssuedShares) * 100).toFixed(2);
      return (
        <div className="custom-tooltip">
          <p className="tooltip-title">{data.name}</p>
          {data.name !== 'Unallotted Shares' && (
            <p className="tooltip-folio">Folio Number: {data.folioNumber}</p>
          )}
          <p className="tooltip-shares">Shares Held: {data.totalShares.toLocaleString()}</p>
          <p className="tooltip-percentage">Percentage: {percentage}%</p>
        </div>
      );
    }
    return null;
  };

  // Extract top 5 shareholders for bar chart (just as before)
  const top5 = chartData
    .filter(d => d.name !== 'Unallotted Shares')
    .slice(0, 5);

  // Calculate allotted percentage for trend indicator (if needed)
  const allottedPercentage = ((totalAllottedShares / totalIssuedShares) * 100).toFixed(2);

  return (
    <div className="dashboard-container">
      <Row gutter={[24, 24]}>
        <Col xs={24}>
          <CompanyInfoCard />
        </Col>
      </Row>

      {/* Main Stats */}
      <Row gutter={[24, 24]} className="dashboard-stats">
        <Col xs={24} sm={12} lg={6}>
          <StatCard
            title="Total Shares"
            value={totalIssuedShares}
            info="Total number of shares issued by the company"
            icon={<PieChartOutlined style={{ marginRight: 8, fontSize: 16 }} />}
          />
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <StatCard
            title="Allotted Shares"
            value={totalAllottedShares}
            info="Number of shares currently allotted"
            trend={allottedPercentage}
            icon={<StockOutlined style={{ marginRight: 8, fontSize: 16 }} />}
          />
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <StatCard
            title="Unallotted Shares"
            value={unallottedShares}
            info="Number of shares available for allotment"
            icon={<UsergroupAddOutlined style={{ marginRight: 8, fontSize: 16 }} />}
          />
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <StatCard
            title="Share Price"
            value={companyData.nominalShareValue}
            prefix="₹"
            info="Nominal value per share"
            icon={<DollarCircleOutlined style={{ marginRight: 8, fontSize: 16 }} />}
          />
        </Col>
      </Row>

      {/* Charts and Visualizations */}
      <Row gutter={[24, 24]} className="dashboard-charts">
        <Col xs={24} lg={16}>
          <Card className="chart-card shareholding-card">
            <h3 className="chart-title">
              Shareholding Pattern
              <Tooltip title="Distribution of shares among active shareholders and unallotted shares.">
                <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 16, color: '#999' }} />
              </Tooltip>
            </h3>
            <div className="chart-content">
              <div className="chart-section">
                <ResponsiveContainer width="100%" height={320}>
                  <PieChart>
                    <Pie
                      data={chartData}
                      cx="50%"
                      cy="45%"
                      innerRadius={70}
                      outerRadius={130}
                      paddingAngle={2}
                      dataKey="value"
                    >
                      {chartData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <RechartsTooltip content={<CustomTooltipContent />} />
                    <Legend
                      layout="horizontal"
                      align="center"
                      verticalAlign="bottom"
                      wrapperStyle={{ marginTop: '10px', fontSize: '14px' }}
                    />
                  </PieChart>
                </ResponsiveContainer>
              </div>
              <div className="stats-section">
                <div className="stats-item">
                  <span className="stats-label">Largest Holder:</span>
                  {largestHolder ? (
                    <>
                      <span className="stats-value">{largestHolder.name}</span>
                      <span className="stats-subtext">{largestHolderPercentage}% of total</span>
                    </>
                  ) : (
                    <span className="stats-value">N/A</span>
                  )}
                </div>
                <div className="stats-item">
                  <span className="stats-label">Avg. Shares/Active SH:</span>
                  <span className="stats-value">{avgSharesPerActive.toLocaleString()}</span>
                </div>
                <div className="stats-item">
                  <span className="stats-label">Shareholders &lt;1%:</span>
                  <span className="stats-value">{smallHoldersCount}</span>
                </div>
              </div>
            </div>
          </Card>
        </Col>

        <Col xs={24} lg={8}>
          <Card className="top-shareholders-card">
            <h3 className="card-title">Top Shareholders</h3>
            <div className="shareholders-list">
              {chartData.slice(0, 5).map((shareholder, index) => (
                <div
                  key={index}
                  className={`shareholder-row ${shareholder.name === 'Unallotted Shares' ? 'unallotted' : ''}`}
                >
                  <div className="shareholder-info-container">
                    {shareholder.name !== 'Unallotted Shares' && (
                      <Avatar className="shareholder-avatar">
                        {shareholder.name.charAt(0).toUpperCase()}
                      </Avatar>
                    )}
                    <div className="shareholder-info">
                      <span className="shareholder-name">{shareholder.name}</span>
                      {shareholder.name !== 'Unallotted Shares' && (
                        <span className="shareholder-folio">Folio: {shareholder.folioNumber}</span>
                      )}
                    </div>
                  </div>
                  <div className="shareholder-shares">
                    <span className="shares-count">
                      {shareholder.totalShares.toLocaleString()} shares
                    </span>
                    <span className="shares-percentage">
                      {((shareholder.totalShares / totalIssuedShares) * 100).toFixed(2)}%
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </Card>
        </Col>
      </Row>

      {historicalData && historicalData.length > 0 && (
        <Row gutter={[24, 24]} style={{ marginTop: '20px' }}>
          <Col xs={24} lg={12}>
            <Card className="chart-card trend-card">
              <h3 className="chart-title">
                Historical Allotment Trend <Tooltip title="Shows how allotted shares changed monthly"><InfoCircleOutlined /></Tooltip>
              </h3>
              <div className="chart-container">
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart data={historicalData} margin={{ top: 20, right: 30, left: 0, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <RechartsTooltip />
                    <Legend />
                    <Line type="monotone" dataKey="sharesAllotted" stroke="#2193b0" dot={true} name="Allotted Shares" />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </Card>
          </Col>

          <Col xs={24} lg={12}>
            <Card className="chart-card top-bar-card">
              <h3 className="chart-title">
                Top 5 Shareholders (Bar) <Tooltip title="Comparison of top shareholders' holdings"><InfoCircleOutlined /></Tooltip>
              </h3>
              <div className="chart-container" style={{ height: '300px' }}>
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart data={top5} layout="vertical" margin={{ top: 20, right: 30, left: 80, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number" />
                    <YAxis dataKey="name" type="category" width={150} />
                    <RechartsTooltip />
                    <Legend />
                    <Bar dataKey="totalShares" fill="#4caf50" name="Shares Held" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default Dashboard;