import React, { useContext, useState } from 'react';
import { Form, Input, InputNumber, Button, Modal, Steps } from 'antd';
import { AuthContext } from '../contexts/AuthContext';
import { db } from '../firebase';
import { doc, setDoc } from 'firebase/firestore';
import { validateCIN } from '../utils/cinValidator';
import { useNavigate } from 'react-router-dom';
import '../styles/pages.css';

const { Step } = Steps;

const CompleteProfile = () => {
  const { currentUser, setCompanyData } = useContext(AuthContext);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const companyRef = doc(db, 'companies', currentUser.uid);

      const companyData = {
        ...values,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        uid: currentUser.uid,
        status: 'active'
      };

      await setDoc(companyRef, companyData, { merge: true });
      setCompanyData(companyData);
      
      Modal.success({
        title: 'Welcome Aboard!',
        content: 'Your company profile has been set up successfully. You can now start managing your shareholdings.',
        onOk: () => navigate('/'),
      });
    } catch (error) {
      console.error('Error saving company details:', error);
      Modal.error({
        title: 'Error',
        content: 'An error occurred while saving company details. Please try again.',
      });
    }
    setLoading(false);
  };

  return (
    <div className="page-container">
      <div className="form-section" style={{ maxWidth: '800px', margin: '50px auto' }}>
        <Steps
          current={0}
          style={{ marginBottom: '40px' }}
          items={[
            { title: 'Company Details', description: 'Basic Information' },
            { title: 'Ready', description: 'Start Managing' }
          ]}
        />

        <h2 className="form-title">Complete Your Company Profile</h2>
        <p style={{ marginBottom: '24px', color: 'var(--text-secondary)' }}>
          Please provide your company details to get started. This information will be used
          throughout the application for managing your shareholdings.
        </p>

        <Form 
          form={form} 
          onFinish={onFinish} 
          layout="vertical"
          requiredMark={false}
          className="custom-form"
        >
          <Form.Item
            label="Company Name"
            name="companyName"
            rules={[
              { required: true, message: 'Please input your company name!' },
              { min: 3, message: 'Company name must be at least 3 characters long' }
            ]}
          >
            <Input placeholder="Enter your company's registered name" />
          </Form.Item>

          <Form.Item
            label="Corporate Identification Number (CIN)"
            name="cin"
            rules={[{ validator: validateCIN }]}
            tooltip="Format: U74999TN2023PTC123456"
          >
            <Input 
              maxLength={21} 
              placeholder="Enter your company's CIN"
              showCount
            />
          </Form.Item>

          <Form.Item
            label="Authorized Capital (INR)"
            name="authorizedCapital"
            rules={[
              { required: true, message: 'Please input authorized capital!' },
              { type: 'number', min: 100000, message: 'Authorized capital must be at least ₹1,00,000' }
            ]}
            tooltip="The maximum amount of share capital that your company is authorized to issue"
          >
            <InputNumber
              className="w-full"
              formatter={(value) => `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/₹\s?|(,*)/g, '')}
              min={100000}
              placeholder="Enter authorized capital"
            />
          </Form.Item>

          <Form.Item
            label="Paid-up Capital (INR)"
            name="paidUpCapital"
            rules={[
              { required: true, message: 'Please input paid-up capital!' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('authorizedCapital') >= value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Paid-up capital cannot exceed authorized capital!'));
                },
              }),
            ]}
            tooltip="The amount of share capital that has been issued to shareholders and paid for"
          >
            <InputNumber
              className="w-full"
              formatter={(value) => `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/₹\s?|(,*)/g, '')}
              min={0}
              placeholder="Enter paid-up capital"
            />
          </Form.Item>

          <Form.Item
            label="Nominal Value of Share (INR)"
            name="nominalShareValue"
            rules={[
              { required: true, message: 'Please input nominal share value!' },
              { type: 'number', min: 1, message: 'Nominal value must be greater than 0' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('paidUpCapital') % value === 0) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Paid-up capital should be divisible by nominal share value!'));
                },
              }),
            ]}
            tooltip="The face value of each share (usually ₹10 or ₹100)"
          >
            <InputNumber
              className="w-full"
              formatter={(value) => `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/₹\s?|(,*)/g, '')}
              min={1}
              placeholder="Enter nominal share value"
            />
          </Form.Item>

          <Form.Item>
            <Button 
              className="custom-button" 
              type="primary" 
              htmlType="submit" 
              loading={loading}
              block
              size="large"
            >
              Complete Setup
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default CompleteProfile;