// src/pages/legal/PrivacyPolicy.js

import React from 'react';
import { Typography, Breadcrumb, Layout } from 'antd';
import { Link } from 'react-router-dom';
import AppFooter from '../../components/Layout/Footer';
import LandingNav from '../../components/Layout/LandingNav';
import '../../styles/Legal.css';

const { Title, Paragraph, Text } = Typography;
const { Content } = Layout;

const PrivacyPolicy = () => {
  return (
    <Layout>
      <LandingNav title="lumioCapital" />
      
      <Content>
        <div className="legal-container">
          <div className="legal-content">
            <div className="legal-navigation">
              <Breadcrumb>
                <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item>Privacy Policy</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="legal-header">
              <Title level={1}>Privacy Policy</Title>
              <Text className="last-updated">Last Updated: December 5, 2024</Text>
            </div>

            <div className="legal-section">
              <section className="legal-introduction">
                <Paragraph>
                  This Privacy Policy ("Policy") is published in compliance with:
                </Paragraph>
                <ul>
                  <li>Section 43A of the Information Technology Act, 2000;</li>
                  <li>Regulation 4 of the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011 (the "SPI Rules");</li>
                  <li>Regulation 3(1) of the Information Technology (Intermediaries Guidelines) Rules, 2011.</li>
                </ul>
              </section>

              <section>
                <Title level={2}>1. Definitions</Title>
                <Paragraph>
                  For the purpose of this Policy:
                </Paragraph>
                <ul>
                  <li><Text strong>"Company"</Text> means Lumiotech Private Limited, a company incorporated under the Companies Act, 2013 having its registered office in New Delhi, India</li>
                  <li><Text strong>"Personal Information"</Text> means any information that relates to a natural person, which, either directly or indirectly, in combination with other information available or likely to be available with a body corporate, is capable of identifying such person;</li>
                  <li><Text strong>"Sensitive Personal Information"</Text> means personal information as defined under the SPI Rules;</li>
                  <li><Text strong>"Services"</Text> means the lumioCapital platform and related services;</li>
                  <li><Text strong>"User"</Text> means any person who accesses or uses the Services.</li>
                </ul>
              </section>

              <section>
                <Title level={2}>2. Information We Collect</Title>
                
                <Title level={3}>2.1 Personal Information</Title>
                <Paragraph>
                  We collect the following categories of Personal Information:
                </Paragraph>
                <ul>
                  <li>Company registration details (CIN, authorized capital, paid-up capital)</li>
                  <li>Director and authorized signatory information</li>
                  <li>Contact details (email address, phone number, address)</li>
                  <li>Shareholder information and records</li>
                  <li>Financial information related to shareholding</li>
                  <li>Authentication credentials</li>
                </ul>

                <Title level={3}>2.2 Automatically Collected Information</Title>
                <Paragraph>
                  Our systems automatically collect:
                </Paragraph>
                <ul>
                  <li>IP address and access logs</li>
                  <li>Device and browser information</li>
                  <li>Usage patterns and analytics</li>
                  <li>Cookies and similar tracking technologies</li>
                </ul>
              </section>

              <section>
                <Title level={2}>3. Legal Basis for Processing</Title>
                <Paragraph>
                  We process your information based on:
                </Paragraph>
                <ul>
                  <li>Explicit consent provided by you</li>
                  <li>Contractual necessity</li>
                  <li>Legal obligations under Indian law</li>
                  <li>Legitimate business interests</li>
                </ul>
              </section>

              <section>
                <Title level={2}>4. Use of Information</Title>
                <Paragraph>
                  By using our Services, you explicitly consent to:
                </Paragraph>
                <ul>
                  <li>Processing of share transfers and maintaining statutory records</li>
                  <li>Generation of reports and regulatory filings</li>
                  <li>Communication regarding service updates and notices</li>
                  <li>Compliance with legal and regulatory requirements</li>
                  <li>Fraud detection and security enhancement</li>
                  <li>Service improvement and user experience optimization</li>
                </ul>
              </section>

              <section>
                <Title level={2}>5. Data Storage and Security</Title>
                <Paragraph>
                  We implement security measures as prescribed under:
                </Paragraph>
                <ul>
                  <li>ISO/IEC 27001 standards for information security</li>
                  <li>The Information Technology Act, 2000</li>
                  <li>The SPI Rules</li>
                </ul>
                <Paragraph>
                  However, no method of transmission over the internet or electronic storage is 100% secure. While we strive to protect your Personal Information, we cannot guarantee its absolute security.
                </Paragraph>
              </section>

              <section>
                <Title level={2}>6. Disclosure of Information</Title>
                <Paragraph>
                  We may disclose your information to:
                </Paragraph>
                <ul>
                  <li>Regulatory authorities (SEBI, ROC, etc.)</li>
                  <li>Law enforcement agencies upon lawful request</li>
                  <li>Professional advisors and auditors</li>
                  <li>Service providers and business partners</li>
                  <li>Other users as necessary for share transfers</li>
                </ul>
              </section>

              <section>
                <Title level={2}>7. Data Subject Rights</Title>
                <Paragraph>
                  Under applicable law, you have the right to:
                </Paragraph>
                <ul>
                  <li>Access your Personal Information</li>
                  <li>Correct inaccurate information</li>
                  <li>Request deletion (subject to legal requirements)</li>
                  <li>Withdraw consent</li>
                  <li>File complaints with relevant authorities</li>
                </ul>
              </section>

              <section>
                <Title level={2}>8. Limitation of Liability</Title>
                <Paragraph>
                  THE COMPANY SHALL NOT BE LIABLE FOR:
                </Paragraph>
                <ul>
                  <li>Any unauthorized access to or alteration of your information</li>
                  <li>Any actions or omissions of third parties</li>
                  <li>Any direct, indirect, incidental, consequential or punitive damages arising from use of our Services</li>
                  <li>Technical or security breaches beyond our reasonable control</li>
                </ul>
              </section>

              <section>
                <Title level={2}>9. Changes to Privacy Policy</Title>
                <Paragraph>
                  We reserve the right to modify this Policy at any time. Changes will be effective immediately upon posting on our platform. Your continued use of the Services constitutes acceptance of such changes.
                </Paragraph>
              </section>

              <section>
                <Title level={2}>10. Grievance Officer</Title>
                <Paragraph>
                  In accordance with Information Technology Act 2000 and rules made there under, the contact details of the Grievance Officer are provided below:
                </Paragraph>
                <div className="legal-contact">
                  <Title level={3}>Grievance Officer</Title>
                  <Paragraph>
                    Email: legal@lumiotech.in<br />
                  </Paragraph>
                </div>
              </section>
            </div>
          </div>
        </div>
      </Content>

      <AppFooter />
    </Layout>
  );
};

export default PrivacyPolicy;