// src/pages/legal/TermsOfService.js

import React from 'react';
import { Typography, Breadcrumb, Layout } from 'antd';
import { Link } from 'react-router-dom';
import AppFooter from '../../components/Layout/Footer';
import LandingNav from '../../components/Layout/LandingNav';
import '../../styles/Legal.css';

const { Title, Paragraph, Text } = Typography;
const { Content } = Layout;

const TermsOfService = () => {
  return (
    <Layout>
      <LandingNav title="lumioCapital" />
      
      <Content>
        <div className="legal-container">
          <div className="legal-content">
            <div className="legal-navigation">
              <Breadcrumb>
                <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item>Terms of Service</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="legal-header">
              <Title level={1}>Terms of Service</Title>
              <Text className="last-updated">Last Updated: December 5, 2024</Text>
            </div>

            <div className="legal-section">
              <section className="legal-introduction">
                <Paragraph>
                  These Terms of Service ("Terms") constitute a legally binding agreement between you ("User", "you", or "your") and Lumiotech Private Limited ("Company", "we", "us", or "our") regarding your use of the lumioCapital platform and related services (collectively, the "Services").
                </Paragraph>
              </section>

              <section>
                <Title level={2}>1. Acceptance of Terms</Title>
                <Paragraph>
                  By accessing or using our Services, you agree to be bound by these Terms, our Privacy Policy, and all applicable laws and regulations. If you do not agree with any part of these Terms, you must not use our Services.
                </Paragraph>
              </section>

              <section>
                <Title level={2}>2. Eligibility</Title>
                <Paragraph>
                  You represent and warrant that:
                </Paragraph>
                <ul>
                  <li>You are authorized to represent and bind the company on whose behalf you are using the Services</li>
                  <li>Your company is duly incorporated under the laws of India</li>
                  <li>You have the authority to enter into this agreement</li>
                  <li>All information provided is accurate and complete</li>
                </ul>
              </section>

              <section>
                <Title level={2}>3. Service Description</Title>
                <Title level={3}>3.1 Core Services</Title>
                <Paragraph>
                  The Services include:
                </Paragraph>
                <ul>
                  <li>Digital share registry management</li>
                  <li>Share transfer processing</li>
                  <li>Shareholder record maintenance</li>
                  <li>Report generation and analytics</li>
                </ul>

                <Title level={3}>3.2 Service Limitations</Title>
                <Paragraph>
                  You acknowledge that:
                </Paragraph>
                <ul>
                  <li>The Services are not a substitute for legal advice</li>
                  <li>We do not guarantee regulatory compliance</li>
                  <li>Service availability may be subject to interruptions</li>
                </ul>
              </section>

              <section>
                <Title level={2}>4. User Obligations</Title>
                <Paragraph>
                  You agree to:
                </Paragraph>
                <ul>
                  <li>Provide accurate and complete information</li>
                  <li>Maintain the confidentiality of your account credentials</li>
                  <li>Comply with all applicable laws and regulations</li>
                  <li>Not use the Services for any unlawful purpose</li>
                  <li>Notify us immediately of any unauthorized access</li>
                </ul>
              </section>

              <section>
                <Title level={2}>5. Data Usage and Privacy</Title>
                <Paragraph>
                  By using our Services, you explicitly consent to:
                </Paragraph>
                <ul>
                  <li>Collection and processing of company and shareholder data</li>
                  <li>Storage of data on our servers</li>
                  <li>Use of data for service improvement</li>
                  <li>Sharing of data with authorized third parties</li>
                </ul>
              </section>

              <section>
                <Title level={2}>6. Fees and Payment</Title>
                <Paragraph>
                  6.1 Service fees are as per the pricing plan selected by you.
                </Paragraph>
                <Paragraph>
                  6.2 We reserve the right to modify our pricing with 30 days notice.
                </Paragraph>
                <Paragraph>
                  6.3 All fees are non-refundable unless required by law.
                </Paragraph>
              </section>

              <section>
                <Title level={2}>7. Intellectual Property</Title>
                <Paragraph>
                  7.1 All content, features, and functionality are owned by the Company and protected by intellectual property laws.
                </Paragraph>
                <Paragraph>
                  7.2 You may not copy, modify, distribute, or reverse engineer any part of the Services.
                </Paragraph>
              </section>

              <section>
                <Title level={2}>8. Disclaimer of Warranties</Title>
                <Paragraph>
                  THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT ANY WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO:
                </Paragraph>
                <ul>
                  <li>Merchantability and fitness for a particular purpose</li>
                  <li>Accuracy or reliability of results</li>
                  <li>Uninterrupted or error-free operation</li>
                  <li>Compatibility with your systems</li>
                </ul>
              </section>

              <section>
                <Title level={2}>9. Limitation of Liability</Title>
                <Paragraph>
                  TO THE MAXIMUM EXTENT PERMITTED BY LAW:
                </Paragraph>
                <ul>
                  <li>We shall not be liable for any indirect, incidental, special, consequential, or punitive damages</li>
                  <li>Our total liability shall not exceed the amount paid by you for the Services in the past 12 months</li>
                  <li>We are not liable for any loss of data, profits, or business opportunities</li>
                </ul>
              </section>

              <section>
                <Title level={2}>10. Indemnification</Title>
                <Paragraph>
                  You agree to indemnify and hold harmless the Company from any claims arising from:
                </Paragraph>
                <ul>
                  <li>Your use of the Services</li>
                  <li>Violation of these Terms</li>
                  <li>Infringement of third-party rights</li>
                  <li>Any content or data you provide</li>
                </ul>
              </section>

              <section>
                <Title level={2}>11. Term and Termination</Title>
                <Paragraph>
                  11.1 These Terms remain in effect until terminated.
                </Paragraph>
                <Paragraph>
                  11.2 We may terminate or suspend your access immediately without notice for any violation of these Terms.
                </Paragraph>
                <Paragraph>
                  11.3 Upon termination:
                </Paragraph>
                <ul>
                  <li>All rights granted to you will cease</li>
                  <li>You must cease all use of the Services</li>
                  <li>Certain provisions will survive termination</li>
                </ul>
              </section>

              <section>
                <Title level={2}>12. Governing Law</Title>
                <Paragraph>
                  These Terms shall be governed by and construed in accordance with the laws of India. Any disputes shall be subject to the exclusive jurisdiction of the courts in [City], India.
                </Paragraph>
              </section>

              <section>
                <Title level={2}>13. Contact Information</Title>
                <div className="legal-contact">
                  <Title level={3}>Questions about our Terms?</Title>
                  <Paragraph>
                    For any questions regarding these Terms, please contact:<br />
                    Email: legal@lumiotech.in<br />
                  </Paragraph>
                </div>
              </section>
            </div>
          </div>
        </div>
      </Content>

      <AppFooter />
    </Layout>
  );
};

export default TermsOfService;