// src/pages/NotFound.js

import React from 'react';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';
import '../styles/pages.css';

const NotFound = () => (
  <div className="page-container">
    <div className="custom-card">
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Link to="/">
            <Button type="primary" className="custom-button">
              Back Home
            </Button>
          </Link>
        }
      />
    </div>
  </div>
);

export default NotFound;