import React, { createContext, useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail
} from 'firebase/auth';
import { doc, setDoc, getDoc } from 'firebase/firestore';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [authLoading, setAuthLoading] = useState(false);
  const [companyData, setCompanyData] = useState(null);
  const [profileComplete, setProfileComplete] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setLoading(true);
      setCurrentUser(user);
      if (user) {
        try {
          const companyRef = doc(db, 'companies', user.uid);
          const companySnap = await getDoc(companyRef);
          if (companySnap.exists()) {
            const data = companySnap.data();
            setCompanyData(data);
            setProfileComplete(true);
          } else {
            setCompanyData(null);
            setProfileComplete(false);
          }
        } catch (error) {
          console.error('Error fetching company data:', error);
          setCompanyData(null);
          setProfileComplete(false);
        }
      } else {
        setCompanyData(null);
        setProfileComplete(false);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const register = async (email, password, companyDetails) => {
    setAuthLoading(true);
    try {
      const { user } = await createUserWithEmailAndPassword(auth, email, password);
      if (companyDetails) {
        const companyRef = doc(db, 'companies', user.uid);
        await setDoc(companyRef, {
          ...companyDetails,
          uid: user.uid,
          createdAt: new Date().toISOString(),
        });
        setCompanyData(companyDetails);
        setProfileComplete(true);
      }
      return user;
    } catch (error) {
      throw new Error(error.message);
    } finally {
      setAuthLoading(false);
    }
  };

  const login = async (email, password) => {
    setAuthLoading(true);
    try {
      const { user } = await signInWithEmailAndPassword(auth, email, password);
      const companyRef = doc(db, 'companies', user.uid);
      const companySnap = await getDoc(companyRef);
      
      if (companySnap.exists()) {
        const data = companySnap.data();
        setCompanyData(data);
        setProfileComplete(true);
      } else {
        setCompanyData(null);
        setProfileComplete(false);
      }
      
      return user;
    } catch (error) {
      throw new Error(error.message);
    } finally {
      setAuthLoading(false);
    }
  };

  const googleLogin = async () => {
    setAuthLoading(true);
    try {
      const provider = new GoogleAuthProvider();
      const { user } = await signInWithPopup(auth, provider);
      const companyRef = doc(db, 'companies', user.uid);
      const companySnap = await getDoc(companyRef);
      
      if (companySnap.exists()) {
        const data = companySnap.data();
        setCompanyData(data);
        setProfileComplete(true);
      } else {
        setCompanyData(null);
        setProfileComplete(false);
      }
      
      return user;
    } catch (error) {
      throw new Error(error.message);
    } finally {
      setAuthLoading(false);
    }
  };

  const logout = async () => {
    setAuthLoading(true);
    try {
      await signOut(auth);
      setCompanyData(null);
      setProfileComplete(false);
      setCurrentUser(null);
    } catch (error) {
      throw new Error(error.message);
    } finally {
      setAuthLoading(false);
    }
  };

  const resetPassword = async (email) => {
    setAuthLoading(true);
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      throw new Error(error.message);
    } finally {
      setAuthLoading(false);
    }
  };

  const value = {
    currentUser,
    companyData,
    profileComplete,
    loading,
    authLoading,
    login,
    register,
    logout,
    googleLogin,
    setCompanyData,
    resetPassword,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;