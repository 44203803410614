import React, { useEffect, useState, useCallback, useContext } from 'react';
import { db } from '../../firebase';
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  deleteDoc,
  query,
  where,
} from 'firebase/firestore';
import {
  Table,
  Card,
  Button,
  Modal,
  Form,
  Input,
  InputNumber,
  Typography,
  Tag,
  Space,
  Tooltip,
  Badge,
  Alert,
  Row,
  Col
} from 'antd';
import {
  InfoCircleOutlined,
  TeamOutlined,
  HistoryOutlined,
  CheckCircleOutlined,
  StopOutlined
} from '@ant-design/icons';
import { AuthContext } from '../../contexts/AuthContext';
import moment from 'moment';

const { Text } = Typography;
const { Paragraph } = Typography;

const ShareholdersList = () => {
  const [shareholders, setShareholders] = useState([]);
  const [editingShareholder, setEditingShareholder] = useState(null);
  const [editForm] = Form.useForm();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState('');
  const [certificateHistoryModal, setCertificateHistoryModal] = useState({ visible: false, chain: [] });
  const { currentUser } = useContext(AuthContext);

  const calculateTotalShares = (certificates) => {
    return certificates.reduce((total, cert) => {
      // Skip cancelled certificates
      if (cert.status === 'Cancelled') return total;

      // Handle certificates with distinctive ranges
      if (cert.distinctiveRanges) {
        return total + cert.distinctiveRanges.reduce((rangeTotal, range) =>
          rangeTotal + (range.end - range.start + 1), 0);
      }

      // Handle certificates with start/end numbers
      if (cert.distinctiveNumberStart && cert.distinctiveNumberEnd) {
        return total + (cert.distinctiveNumberEnd - cert.distinctiveNumberStart + 1);
      }

      // Handle certificates with just totalShares
      return total + (cert.totalShares || 0);
    }, 0);
  };

  const fetchShareholders = useCallback(async () => {
    try {
      if (!currentUser) return;
      const shareholdersRef = collection(db, 'shareholders');
      const q = query(shareholdersRef, where("companyId", "==", currentUser.uid));
      const querySnapshot = await getDocs(q);
      const data = querySnapshot.docs.map((doc) => ({
        key: doc.id,
        ...doc.data(),
        certificates: (doc.data().certificates || []).map(cert => ({
          ...cert,
          key: cert.certificateNumber,
          status: cert.status || 'Active'
        })),
      }));
      setShareholders(data);
    } catch (error) {
      console.error('Error fetching shareholders:', error);
      Modal.error({
        title: 'Error',
        content: 'Failed to fetch shareholders list',
      });
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      fetchShareholders();
    }
  }, [currentUser, fetchShareholders]);

  const handleEdit = (record) => {
    setEditingShareholder(record);
    editForm.setFieldsValue({
      folioNumber: record.folioNumber,
      names: record.names.join(' / '),
      totalShares: calculateTotalShares(record.certificates),
      status: record.status,
    });
  };

  const handleUpdate = async () => {
    try {
      const values = editForm.getFieldsValue();
      const shareholderRef = doc(db, 'shareholders', editingShareholder.key);
      await updateDoc(shareholderRef, {
        ...values,
        names: values.names.split('/').map(name => name.trim()),
        companyId: currentUser.uid,
      });
      setEditingShareholder(null);
      fetchShareholders();
      Modal.success({
        title: 'Success',
        content: 'Shareholder updated successfully.',
      });
    } catch (error) {
      console.error(error);
      Modal.error({
        title: 'Error',
        content: 'Failed to update shareholder.',
      });
    }
  };

  const handleDelete = async () => {
    if (deleteConfirmation.toLowerCase() !== 'delete') {
      Modal.error({
        title: 'Error',
        content: 'Please type "delete" to confirm.',
      });
      return;
    }

    try {
      const activeCertificates = editingShareholder.certificates.filter(
        cert => cert.status === 'Active'
      );

      if (activeCertificates.length > 0) {
        Modal.error({
          title: 'Cannot Delete',
          content: 'This shareholder has active certificates. Please transfer or cancel all certificates before deleting.'
        });
        return;
      }

      await deleteDoc(doc(db, 'shareholders', editingShareholder.key));
      setEditingShareholder(null);
      setDeleteModalVisible(false);
      setDeleteConfirmation('');
      fetchShareholders();
      Modal.success({
        title: 'Success',
        content: 'Shareholder deleted successfully.',
      });
    } catch (error) {
      console.error(error);
      Modal.error({
        title: 'Error',
        content: 'Failed to delete shareholder.',
      });
    }
  };

  const getCertificateStatusTag = (status) => {
    switch (status) {
      case 'Active':
        return <Tag color="success" icon={<CheckCircleOutlined />}>Active</Tag>;
      case 'Cancelled':
        return <Tag color="error" icon={<StopOutlined />}>Cancelled</Tag>;
      default:
        return <Tag>{status}</Tag>;
    }
  };

  /**
   * Build a full certificate history chain by recursively finding previous owners.
   * The idea is:
   * 1. Start with the current certificate.
   * 2. Find the certificate's previousCertificate (if any).
   * 3. Locate that previous certificate in the entire shareholders array.
   * 4. Keep going back until we find the earliest ancestor (no previousCertificate).
   * 5. We then have a chain from earliest to latest. Return the chain sorted by date.
   */
  const buildCertificateHistoryChain = (currentCert) => {
    const allCertificates = [];

    // Flatten all certificates from all shareholders into a single array
    shareholders.forEach(holder => {
      (holder.certificates || []).forEach(cert => {
        allCertificates.push({
          ...cert,
          holderName: holder.names.join(' / '),
          folioNumber: holder.folioNumber
        });
      });
    });

    // Helper function to find a certificate by its number
    const findCertByNumber = (number) => {
      return allCertificates.find(c => c.certificateNumber === number);
    };

    const chain = [];
    let cursor = currentCert;

    // Move backwards through previousCertificates to find ancestors
    while (cursor) {
      chain.unshift(cursor); // Insert at the start to have oldest at the front
      if (!cursor.previousCertificate) break;
      const prev = findCertByNumber(cursor.previousCertificate);
      cursor = prev || null;
    }

    // Now chain has earliest at 0 and current at the end.
    // We could also try to find descendants if needed, but usually we want from earliest to current.
    return chain;
  };

  const displayCertificateHistory = (certificate) => {
    const chain = buildCertificateHistoryChain(certificate);

    setCertificateHistoryModal({
      visible: true,
      chain: chain
    });
  };

  const columns = [
    {
      title: 'Folio Number',
      dataIndex: 'folioNumber',
      key: 'folioNumber',
      sorter: (a, b) => a.folioNumber.localeCompare(b.folioNumber),
    },
    {
      title: 'Names',
      dataIndex: 'names',
      key: 'names',
      render: (names, record) => (
        <Space>
          {record.isJoint && (
            <Tooltip title="Joint Shareholding">
              <TeamOutlined style={{ color: '#1890ff' }} />
            </Tooltip>
          )}
          {names ? names.join(' / ') : ''}
        </Space>
      ),
      sorter: (a, b) => a.names[0].localeCompare(b.names[0]),
    },
    {
      title: 'Total Shares',
      dataIndex: 'certificates',
      key: 'totalShares',
      render: (certificates) => {
        const total = calculateTotalShares(certificates);
        return total.toLocaleString();
      },
      sorter: (a, b) => calculateTotalShares(a.certificates) - calculateTotalShares(b.certificates),
    },
    {
      title: 'Active Certificates',
      key: 'activeCertificates',
      render: (_, record) => {
        const activeCerts = record.certificates.filter(cert =>
          cert.status !== 'Cancelled' &&
          (cert.distinctiveNumberStart || cert.distinctiveRanges)
        );
        return (
          <Badge
            count={activeCerts.length}
            style={{ backgroundColor: '#52c41a' }}
            showZero
          />
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => getCertificateStatusTag(status),
      sorter: (a, b) => a.status?.localeCompare(b.status),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Button type="link" onClick={() => handleEdit(record)}>
            Edit
          </Button>
          <Button
            type="link"
            danger
            onClick={() => {
              setEditingShareholder(record);
              setDeleteModalVisible(true);
            }}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  const certificateColumns = [
    {
      title: 'Certificate Number',
      dataIndex: 'certificateNumber',
      key: 'certificateNumber',
      width: '20%',
      render: (text, record) => (
        <Space direction="vertical" size={0}>
          <Space align="center">
            <Text>{text}</Text>
            <Button
              type="link"
              size="small"
              icon={<HistoryOutlined />}
              onClick={() => displayCertificateHistory(record)}
            >
              History
            </Button>
          </Space>
          <Space size={4}>
            {getCertificateStatusTag(record.status)}
            {record.previousCertificate && (
              <Tag color="blue">Previous: {record.previousCertificate}</Tag>
            )}
          </Space>
          {record.cancellationDate && (
            <Text type="secondary">
              Cancelled: {moment(record.cancellationDate.toDate()).format('DD/MM/YYYY')}
            </Text>
          )}
        </Space>
      ),
    },
    {
      title: 'Distinctive Numbers',
      key: 'distinctiveNumbers',
      width: '40%',
      render: (_, record) => {
        if (record.distinctiveRanges) {
          return (
            <Space direction="vertical" size={0}>
              {record.distinctiveRanges.map((range, index) => (
                <Text key={index}>
                  {range.start.toLocaleString()} - {range.end.toLocaleString()}
                  {record.transferType === 'partial' && (
                    <Tag color="purple" style={{ marginLeft: 8 }}>Partial</Tag>
                  )}
                </Text>
              ))}
            </Space>
          );
        }
        return (
          <div>
            <Text>
              {record.distinctiveNumberStart?.toLocaleString()} - {record.distinctiveNumberEnd?.toLocaleString()}
            </Text>
            {record.transferType === 'partial' && (
              <Tag color="purple" style={{ marginLeft: 8 }}>Partial Range</Tag>
            )}
          </div>
        );
      },
    },
    {
      title: 'Total Shares',
      key: 'totalShares',
      width: '20%',
      render: (_, record) => {
        if (record.distinctiveRanges) {
          const total = record.distinctiveRanges.reduce((sum, range) =>
            sum + (range.end - range.start + 1), 0
          );
          return total.toLocaleString();
        }
        const defaultTotal = (record.distinctiveNumberEnd && record.distinctiveNumberStart)
          ? (record.distinctiveNumberEnd - record.distinctiveNumberStart + 1)
          : (record.totalShares || 0);
        return defaultTotal.toLocaleString();
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '20%',
      render: (status) => getCertificateStatusTag(status)
    }
  ];

  const expandedRowRender = (record) => {
    if (!record.certificates || record.certificates.length === 0) {
      return <Text italic>No certificates found</Text>;
    }

    return (
      <div style={{ margin: '0 48px' }}>
        <Table
          columns={certificateColumns}
          dataSource={record.certificates}
          pagination={false}
          rowKey="certificateNumber"
          size="small"
          bordered
          title={() => (
            <Space>
              <Text strong>
                Share Certificates Details for {record.names.join(' / ')}
              </Text>
              {record.isJoint && (
                <Tag color="blue" icon={<TeamOutlined />}>Joint Holding</Tag>
              )}
            </Space>
          )}
          summary={(pageData) => {
            const activeCertificates = pageData.filter(cert => cert.status === 'Active');
            const totalShares = calculateTotalShares(activeCertificates);
            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={2}>
                    <Text strong>Active Certificates Total</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    <Text strong>{totalShares.toLocaleString()}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3} />
                </Table.Summary.Row>
              </>
            );
          }}
        />
      </div>
    );
  };

  return (
    <Card title="Shareholders List" style={{ marginTop: '20px' }}>
      <Table
        columns={columns}
        dataSource={shareholders}
        expandable={{
          expandedRowRender,
          expandRowByClick: true,
          expandIcon: ({ expanded, onExpand, record }) => (
            expanded ? (
              <Button type="link" onClick={e => onExpand(record, e)}>-</Button>
            ) : (
              <Button type="link" onClick={e => onExpand(record, e)}>+</Button>
            )
          ),
        }}
      />

      <Modal
        title="Edit Shareholder"
        visible={!!editingShareholder && !deleteModalVisible}
        onOk={handleUpdate}
        onCancel={() => setEditingShareholder(null)}
        okText="Update"
      >
        <Form form={editForm} layout="vertical">
          <Form.Item
            label="Folio Number"
            name="folioNumber"
            rules={[{ required: true, message: 'Please input the folio number!' }]}
          >
            <Input disabled />
          </Form.Item>

          <Form.Item
            label="Names"
            name="names"
            rules={[{ required: true, message: 'Please input the name(s)!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Total Shares"
            name="totalShares"
          >
            <InputNumber
              min={0}
              style={{ width: '100%' }}
              disabled
              formatter={value => value?.toLocaleString()}
            />
          </Form.Item>

          <Form.Item
            label="Status"
            name="status"
            rules={[{ required: true, message: 'Please input status!' }]}
          >
            <Input disabled />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Confirm Delete"
        visible={deleteModalVisible}
        onOk={handleDelete}
        onCancel={() => {
          setDeleteModalVisible(false);
          setDeleteConfirmation('');
        }}
        okText="Delete"
        okButtonProps={{ danger: true }}
      >
        <p>
          To confirm deletion of shareholder{' '}
          <strong>{editingShareholder?.folioNumber}</strong>, type "delete" below:
        </p>
        <Input
          placeholder="Type 'delete' to confirm"
          value={deleteConfirmation}
          onChange={(e) => setDeleteConfirmation(e.target.value)}
        />
      </Modal>

      {/* History Modal with Timeline */}
      <Modal
        title="Certificate Ownership History"
        visible={certificateHistoryModal.visible}
        onCancel={() => setCertificateHistoryModal({ visible: false, chain: [] })}
        footer={null}
        width={800}
      >
        {certificateHistoryModal.chain.length > 0 ? (
          <div>
            <Alert
              message="Ownership Timeline"
              description="This timeline shows the full chain of ownership from the earliest known certificate to the current one."
              type="info"
              showIcon
              style={{ marginBottom: '16px' }}
            />

            {/* Use Timeline from antd for a neat chronological display */}
            <Space direction="vertical" style={{ width: '100%' }}>
              {certificateHistoryModal.chain.map((cert, idx) => {
                const date = cert.status === 'Cancelled'
                  ? cert.cancellationDate
                  : cert.issueDate;
                const displayDate = date ? moment(date.toDate()).format('DD/MM/YYYY') : 'Unknown Date';

                return (
                  <Card key={cert.certificateNumber} size="small" bordered>
                    <Space direction="vertical" size="small">
                      <Text strong>Certificate: {cert.certificateNumber}</Text>
                      <Text>Holder: {cert.holderName} ({cert.folioNumber})</Text>
                      {cert.transferType && (
                        <Tag color={cert.transferType === 'full' ? 'blue' : 'purple'}>
                          {cert.transferType.charAt(0).toUpperCase() + cert.transferType.slice(1)} Transfer
                        </Tag>
                      )}
                      {getCertificateStatusTag(cert.status)}
                      <Text type="secondary">{displayDate}</Text>
                      {cert.distinctiveNumberStart && cert.distinctiveNumberEnd && (
                        <Text>
                          Distinctive Range: {cert.distinctiveNumberStart.toLocaleString()} - {cert.distinctiveNumberEnd.toLocaleString()}
                        </Text>
                      )}
                      {cert.distinctiveRanges && cert.distinctiveRanges.length > 0 && (
                        <Space direction="vertical" size={0}>
                          <Text strong>Distinctive Ranges:</Text>
                          {cert.distinctiveRanges.map((range, i) => (
                            <Text key={i}>{range.start.toLocaleString()} - {range.end.toLocaleString()}</Text>
                          ))}
                        </Space>
                      )}
                    </Space>
                  </Card>
                );
              })}
            </Space>
          </div>
        ) : (
          <Text>No history found for this certificate.</Text>
        )}
      </Modal>
    </Card>
  );
};

export default ShareholdersList;